import Subheading from './Subheading.js';

const Bounces = () => {
  return (
    <>
      <Subheading>
        What does this mean?
      </Subheading>
      <p>
        A bounce is when the recipient’s email server returns an email as undeliverable. This can happen for a variety of reasons, but the most common cause is that the recipient’s email address doesn’t exist. This might be because they’ve closed their email account, or there’s a typo in their address. But there are also occasions when recipients’ email servers encounter a temporary error and bounce an email that was sent to a valid address.
      </p>
      <Subheading>
        What can I do about it?
      </Subheading>
      <p>
        If you see a bounce that was definitely sent to the correct address, please contact <a href='mailto:support@pianola.net'>support@pianola.net</a> so we can investigate further.
      </p>
    </>
  );
};

export default Bounces;
