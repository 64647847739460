const env = process.env.REACT_APP_ENV || 'local';
const build = env.toUpperCase();

const getEnvVar = (name) => {
  const fullVarName = `REACT_APP_${name}_${build}`;

  return process.env[fullVarName];
};

const graphqlEndpoint = getEnvVar('GRAPHQL_URL');

export { env, getEnvVar, build, graphqlEndpoint };
