const colourSteps = [
  '#01579b',
  '#0d47a1',
  '#1a237e',
  '#311b92',
  '#4a148c',
  '#880e4f',
  '#b71c1c'
];

// takes in a numerator and denominator and chooses a colour from the array above
export const getPinColour = (val, maxVal) => {
  if (!val || !maxVal || val < 0) {
    return colourSteps[0];
  }

  if (val > maxVal) {
    return colourSteps[colourSteps.length - 1];
  }

  const ratio = val / maxVal;
  const colourIdx = Math.round(ratio * (colourSteps.length - 1));

  return colourSteps[colourIdx];
};

export const getMarker = ({
  labelText,
  position,
  colourValue,
  colourMax,
  onClick
}) => {
  const colour = getPinColour(colourValue, colourMax);
  // create a base 64 SVG with the correct colour
  // if no label text, add a white circle in the middle
  const svg = window.btoa(`
    <svg fill="${colour}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 180">
      <circle cx="90" cy="90" r="90" />
      ${!labelText && '<circle cx="90" cy="90" r="25" opacity="0.9" fill="#ffffff" />'}
    </svg>
  `);

  // create marker using the SVG
  const marker = new window.google.maps.Marker({
    position,
    icon: {
      url: `data:image/svg+xml;base64,${svg}`,
      scaledSize: new window.google.maps.Size(30, 30)
    },
    // for some reason rendering the markers without this line leads to buggy behaviour
    // e.g. rendering no markers past a certain zoom point,
    // or rendering markers as a weird 3/4s of a circle
    // see: https://stackoverflow.com/questions/11845916
    optimized: false,
    label: labelText ? {
      text: labelText,
      color: 'rgba(255, 255, 255, 0.9)',
      fontSize: '12px'
    } : undefined,
    // this makes higher numbers appear above lower ones
    zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + (colourValue || 0),
  });

  if (onClick) {
    marker.addListener('click', onClick);
  }

  return marker;
};
