import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  messageContentQueries,
  templateQueries
} from '../../graphql/queries';
import {
  messageContentMutations
} from '../../graphql/mutations';
import TemplateEditor from './Components/TemplateEditor.js';

const Compose = () => {
  // QS
  const { jwt, templateId } = useParams();

  // state
  const [messageContent, setMessageContent] = useState(null);

  // mutations
  const [upsertMessageContent] = useMutation(
    messageContentMutations.upsertMessageContent
  );

  // queries
  const [getTemplate] = useLazyQuery(templateQueries.template, {
    variables: { id: templateId }
  });
  const [getMessageContent] = useLazyQuery(messageContentQueries.messageContent, {
    variables: { jwt: jwt }
  });

  // event handlers
  const handleTemplateUpdate = useCallback((template) => {
    const variables = {
      jwt: jwt,
      messageHTML: template.html,
      messageJSON: template.design,
      messageText: template.text
    };
    upsertMessageContent({ variables });
    setMessageContent(variables);
  }, [jwt, upsertMessageContent, setMessageContent]);

  // lifecycle
  useEffect(() => {
    async function fetchData () {
      // check to see if the messageContent already exists for this message
      // if it does just set state, if it doesn't create the messageContent
      // based on the selected template. This guarantees that the messageContent
      // will exist for later pages even if the user doesn't make any changes
      // to the template/messageContent they see in this page
      const mcData = await getMessageContent();
      if (mcData.data.messageContent) {
        setMessageContent(mcData.data.messageContent);
      } else {
        const templateData = await getTemplate();
        const template = templateData.data.template;
        handleTemplateUpdate({
          html: template.templateHTML,
          design: template.templateJSON,
          text: ''
        });
      }
    }
    fetchData();
  }, [getTemplate, getMessageContent, setMessageContent, handleTemplateUpdate]);

  // render
  if (!messageContent) {
    return null;
  }

  return (
    <div>
      <TemplateEditor
        templateJSON={messageContent.messageJSON}
        onUpdate={handleTemplateUpdate}
      />
    </div>
  );
};

export default Compose;
