import PropTypes from 'prop-types';
import { Grid, Skeleton } from '@mui/material';

const TemplateGridSkeleton = ({ small }) => {
  const items = [];
  for (let i = 0; i < 5; i++) {
    items.push(
      <Grid sx={{margin: '.5rem'}} key={i}>
        <Skeleton
          variant='rectangular'
          width={small ? '10rem' : '15rem'}
          height={small ? '12rem' : '17.5rem'}
        />
      </Grid>
    );
  }
  return (
    <Grid
      container
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
    >
      {
        items
      }
    </Grid>
  );
};

TemplateGridSkeleton.propTypes = {
  small: PropTypes.bool
};

export default TemplateGridSkeleton;
