import { Wrapper } from '@googlemaps/react-wrapper';
import Map from './Map.js';
import { getEnvVar } from '../../../helpers/env.js';

const apiKey = getEnvVar('GMAPS_KEY');

const EventsMap = (props) => {
  return (
    <Wrapper apiKey={apiKey}>
      <Map
        {...props}
      />
    </Wrapper>
  );
};

export default EventsMap;
