import { eventQueries } from '../../../../graphql/queries';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import EventTypeButton from './EventTypeButton.js';
import { styled } from '@mui/material/styles';

import Bounces from './Explainers/Bounces.js';
import Clicks from './Explainers/Clicks.js';
import Delivered from './Explainers/Delivered.js';
import Dropped from './Explainers/Dropped.js';
import SpamReport from './Explainers/SpamReport.js';
import UniqueOpens from './Explainers/UniqueOpens.js';
import NotOpened from './Explainers/NotOpened.js';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  ...theme.views.emailStats.eventPicker.container
}));

const eventTypes = [
  {
    type: 'delivered',
    label: 'Delivered',
    explainerTitle: 'Delivered',
    explainerContent: (<Delivered/>)
  },
  {
    type: 'open',
    label: 'Opened',
    explainerTitle: 'Unique Opens',
    explainerContent: (<UniqueOpens/>)
  },
  {
    type: 'notOpened',
    label: 'Unopened',
    explainerTitle: 'Unopened',
    explainerContent: (<NotOpened/>)
  },
  {
    type: 'click',
    label: 'Clicked',
    explainerTitle: 'Clicks',
    explainerContent: (<Clicks/>)
  },
  {
    type: 'bounce',
    label: 'Bounced',
    explainerTitle: 'Bounced',
    explainerContent: (<Bounces/>)
  },
  {
    type: 'dropped',
    label: 'Dropped',
    explainerTitle: 'Dropped',
    explainerContent: (<Dropped/>)
  },
  {
    type: 'spamreport',
    label: 'Spam',
    explainerTitle: 'Spam Report',
    explainerContent: (<SpamReport/>)
  }
];

const EventTypePicker = (props) => {
  const { jwt, currentEventType, onEventTypePick } = props;
  const { data } = useQuery(eventQueries.eventCountsByMessageId, {
    variables: { jwt }
  });

  const counts = (data && data.eventCountsByMessageId) || {};

  // 'dropped' means the email wasn't even processed, so the total is based on both
  const total = (counts.processed || 0) + (counts.dropped || 0);

  return (
    <Container>
      {eventTypes.map((eventTypeData) => {
        const { type, label, explainerTitle, explainerContent } = eventTypeData;

        // for engagement counts like opens or clicks we want to show unique #s because they're more interesting
        // but for the purposes of the rest of the app, e.g. filtering events for the table,
        // we want to show all opens, incl. non-unique ones
        //
        // for session result emails, there's also an edge case where
        // a results email can be sent more than once for the same session,
        // so we also store unique counts for delivered, processed, etc
        let eventCount = counts['unique' + type] || counts[type] || 0;

        if (type === 'notOpened') {
          const delivered = counts.uniquedelivered || counts.delivered || 0;
          eventCount = delivered - counts.uniqueopen;
        }

        return (
          <EventTypeButton
            key={type}
            label={label}
            explainerTitle={explainerTitle}
            explainerContent={explainerContent}
            onClick={() => onEventTypePick(type)}
            isActive={currentEventType === type}
            eventCount={eventCount}
            totalCount={total}
          />
        );
      })}
    </Container>
  );
};

EventTypePicker.propTypes = {
  jwt: PropTypes.string.isRequired,
  currentEventType: PropTypes.string,
  onEventTypePick: PropTypes.func.isRequired
};

export default EventTypePicker;
