// we control all links in here so this rule is not useful
/* eslint react/jsx-no-target-blank: 0 */
import Subheading from './Subheading.js';

const SpamReport = () => {
  return (
    <>
      <Subheading>
        What does this mean?
      </Subheading>
      <p>
        These are recipients who marked your email as spam in their email program. Often, this happens inadvertently, because many email programs put the spam button right next to the delete button and some people don’t understand the implications of marking as spam instead of just deleting an email.
      </p>
      <Subheading>
        What can I do about it?
      </Subheading>
      <p>
        Please contact the player and ask them if they intended to mark your email as spam. If they did so accidentally, please ask them to undo their spam report so that we can reinstate emails to go to them.
      </p>
      <p>
        We have help articles for how to undo spam reports in some of the major email programs:{' '}
        <a href='https://support.pianola.net/support/solutions/articles/61000129232-reversing-a-spam-report-aol-' target='_blank'>AOL</a>,{' '}
        <a href='https://support.pianola.net/support/solutions/articles/61000129290-reversing-a-spam-report-yahoo-' target='_blank'>Yahoo!</a>,{' '}
        <a href='https://support.pianola.net/support/solutions/articles/61000129273-reversing-a-spam-report-hotmail-' target='_blank'>Hotmail</a>.
      </p>
      <p>
        Please also ask the player to contact <a href='mailto:support@pianola.net'>support@pianola.net</a> once they have completed all the steps in the help article or if they need help.
      </p>
    </>
  );
};

export default SpamReport;
