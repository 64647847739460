import PropTypes from 'prop-types';
import { Card, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const AddTemplateButton = ({ onClick }) => {
  return (
    <Card
      onClick={onClick}
      variant='outlined'
      sx={{
        width: '15rem',
        height: '17.5rem',
        margin: '.5rem',
        border: 'solid 1px #ddd',
        background: '#eee',
        position: 'relative',
        '&:hover': {
          background: '#ddd',
          cursor: 'pointer'
        }
      }}
    >
      <AddIcon
        sx={{
          transform: 'scale(10)',
          color: '#ccc',
          position: 'absolute',
          left: 105,
          top: 100
        }}
      />
      <Typography
        variant='h6'
        sx={{
          color: '#999',
          position: 'absolute',
          left: 40,
          top: 210
        }}
      >
        New from scratch
      </Typography>
    </Card>
  );
};

AddTemplateButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default AddTemplateButton;
