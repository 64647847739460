import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  borderTop: '1px solid #ddd',
  borderBottom: '1px solid #ddd',
  padding: '10px 20px',
  marginTop: '-1px',
  fontSize: '20px',
  fontFamily: theme.typography.headingFontFamily,
  fontWeight: 700,
  ...theme.components.dialog.title
}));

const StyledDialog = styled(Dialog, {
  shouldForwardProp: (prop) =>
    prop !== 'minWidth' &&
    prop !== 'paddingTop' &&
    prop !== 'paddingBottom'
})(({ theme, minWidth, paddingTop, paddingBottom }) => ({
  '& .MuiPaper-root': {
    borderRadius: '5px',
    border: '1px solid #ddd',
    minWidth: minWidth || undefined
  },
  '& .MuiDialog-container': {
    paddingTop: paddingTop,
    paddingBottom: paddingBottom
  }
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  fontSize: '14px',
  padding: '20px'
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 5,
  right: 5
}));


const PEDialog = (props) => {
  const { onClose, title, children, open, minWidth, boundsContainer } = props;

  let paddingTop = 0;
  let paddingBottom = 0;

  // `boundsContainer` is an element that the dialog will get centred over
  // specifically useful when this app is embedded in a really long iframe
  // (e.g. Pianola email stats)
  if (boundsContainer) {
    const rect = boundsContainer.getBoundingClientRect();
    paddingTop = rect.top;
    paddingBottom = document.documentElement.scrollHeight - rect.bottom;
  }

  return (
    <StyledDialog
      onClose={onClose}
      open={open}
      hideBackdrop
      minWidth={minWidth}
      paddingTop={paddingTop + 'px'}
      paddingBottom={paddingBottom + 'px'}
    >
      {
        title && (
          <StyledDialogTitle>
            {title}
            <StyledIconButton
              onClick={onClose}
              disableRipple={true}
            >
              <CloseIcon/>
            </StyledIconButton>
          </StyledDialogTitle>
        )
      }
      <StyledDialogContent>
        {children}
      </StyledDialogContent>
    </StyledDialog>
  );
};

PEDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
  minWidth: PropTypes.number,
  boundsContainer: PropTypes.instanceOf(Element)
};

export default PEDialog;
