import { useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import ClubContext from '../../../Components/ClubContext.js';

const MemberSearchField = (props) => {
  const { memberSearchString, onMemberSearch } = props;
  const [localSearchString, setLocalSearchString] = useState(memberSearchString || '');
  const { organisationName } = useContext(ClubContext);

  const handleTextChange = (e) => {
    setLocalSearchString(e.target.value);
  };

  const handleKeyUp = useCallback((e) => {
    if (e.key === 'Enter') {
      onMemberSearch(localSearchString);
    }
  }, [onMemberSearch, localSearchString]);

  const placeholder = organisationName ? `${organisationName} # or email` : 'Email address';

  return (
    <div>
      <TextField
        label='Find members'
        placeholder={placeholder}
        id='member-search'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon/>
            </InputAdornment>
          )
        }}
        InputLabelProps={{ shrink: true }}
        size='small'
        sx={{ width: 300 }}
        value={localSearchString}
        onChange={handleTextChange}
        onKeyUp={handleKeyUp}
      />
    </div>
  );
};

MemberSearchField.propTypes = {
  onMemberSearch: PropTypes.func.isRequired,
  memberSearchString: PropTypes.string
};

export default MemberSearchField;
