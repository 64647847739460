import PropTypes from 'prop-types';
import { useContext } from 'react';
import PETable from '../PETable/PETable.js';
import ClubContext from '../ClubContext.js';
import { getFormattedDate, getTimezone, getDetailedFormattedTime } from '../../helpers/date.js';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const timezone = getTimezone();

const Container = styled(Box)(({ theme }) => ({
  // the final column in here will be the member note
  // which contains a whole lot of machine text, some of which is one single long word
  // these styles prevent the final column from smooshing the other columns together
  '.MuiTableCell-root:last-child': {
    maxWidth: 200,
    overflowWrap: 'break-word'
  }
}));

const MemberEventsTable = (props) => {
  const { events } = props;
  const { isMs } = useContext(ClubContext);

  // sort events oldest first
  const sortedEvents = [...events];
  sortedEvents.sort((a, b) => {
    if (a.timestamp > b.timestamp) return 1;
    if (a.timestamp < b.timestamp) return -1;
    return 0;
  });

  const columns = [
    'Date',
    'Time ' + (isMs ? `(${timezone})` : ''),
    'Event / Action',
    'Note'
  ];
  const rows = sortedEvents.map(event => {
    return {
      id: event.id,
      data: [
        getFormattedDate(event.timestamp),
        getDetailedFormattedTime(event.timestamp),
        event.eventType,
        event.reason
      ]
    };
  });

  return (
    <Container>
      <PETable
        rows={rows}
        columns={columns}
      />
    </Container>
  );
};

MemberEventsTable.propTypes = {
  events: PropTypes.array.isRequired
};

export default MemberEventsTable;
