import { useState, useEffect } from 'react';
import { templateQueries } from '../graphql/queries';
import { useLazyQuery } from '@apollo/client';

const useTemplatesQuery = (jwt, types) => {
  // we need to turn types into a string so it can be a valid dependency in
  // useEffect (array reference changes every time so causes infinite loop)
  const typesString = JSON.stringify(types);
  const [getTemplates] = useLazyQuery(templateQueries.templates);
  const [templates, setTemplates] = useState(null);

  const onDeleteTemplate = (id) => {
    setTemplates((tgState) => {
      return templates.filter(template => template.id !== id)
    });
  };

  useEffect(() => {
    async function fetchData() {
      const data = await Promise.all(
        JSON.parse(typesString).map((type) => (
          getTemplates({ variables: { jwt, type } })
        ))
      );

      // data is an array of each resolved promise... so a an array of data items
      // that each contain the templates for the given query
      // merge all the template arrays togther
      // const data = [
      //   {
      //     data: { templates: [{ id: 1 }, { id: 2 } ]}
      //   },
      //   {
      //     data: { templates: [{ id: 3 }, { id: 4 } ]}
      //   }
      // ];

      const allTemplates = [];
      data.forEach(dataItem => {
        allTemplates.push(...dataItem.data.templates);
      });

      setTemplates(allTemplates);
    }

    fetchData();
  }, [typesString, jwt, getTemplates]);

  return { templates, onDeleteTemplate };
};

export default useTemplatesQuery;
