import gql from 'graphql-tag';

const messageContentMutations = {
  upsertMessageContent: gql`
    mutation (
      $jwt: String!,
      $messageJSON: JSON,
      $messageHTML: String,
      $messageText: String
    ) {
      upsertMessageContent(
        jwt: $jwt,
        messageJSON: $messageJSON,
        messageHTML: $messageHTML,
        messageText: $messageText
      ) {
        id
      }
    }
  `
};

export default messageContentMutations;
