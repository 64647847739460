import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const CenteredRow = (props) => {
  const { children, colSpan, paddingTop } = props;

  return (
    <TableRow>
      <TableCell
        colSpan={colSpan}
        align='center'
        sx={{ paddingTop: paddingTop && `${paddingTop} !important` }}
      >
        {children}
      </TableCell>
    </TableRow>
  );
};

CenteredRow.propTypes = {
  children: PropTypes.node.isRequired,
  colSpan: PropTypes.number.isRequired,
  paddingTop: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
};

export default CenteredRow;
