import gql from 'graphql-tag';

const templateFragments = {
  partialTemplate: gql`
    fragment PartialTemplate on Template {
      id
      name
      description
      type
      thumbnail
    }
  `,
  fullTemplate: gql`
    fragment FullTemplate on Template {
      id
      name
      description
      owner
      type
      thumbnail
      templateJSON
      templateHTML
      createdBy
    }
  `
};

export default templateFragments;
