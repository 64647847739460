import gql from 'graphql-tag';

const messageContentQueries = {
  messageContent: gql`
    query ($jwt: String!) {
      messageContent(jwt: $jwt) {
        id
        messageJSON
        messageHTML
        messageText
      }
    }
  `
};

export default messageContentQueries;
