import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { authQueries, templateQueries } from '../../../graphql/queries';
import { useLazyQuery } from '@apollo/client';
import BackIcon from '@mui/icons-material/ArrowBackIos';
import SaveIcon from '@mui/icons-material/Save';
import TemplateMeta from './TemplateMeta.js';
import TemplateEditor from './TemplateEditor.js';
import PEButton from '../../../Components/PEButton.js';

const Template = ({ id, onTemplateSaveClick, newTemplate, type }) => {
  const [template, setTemplate] = useState(null);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [emailEditorRef, setEmailEditorRef] = useState(null);

  const navigate = useNavigate();

  const [getIsSuperAdmin] = useLazyQuery(
    authQueries.isSuperAdmin,
    {
      onCompleted: (data) => {
        setIsSuperAdmin(data.isSuperAdmin);
      }
    }
  );

  const [getTemplate] = useLazyQuery(
    templateQueries.template,
    {
      variables: { id },
      onCompleted: (data) => {
        const { name, description, ...rest } = data.template;
        setTemplate({
          ...rest,
          name: newTemplate ? '' : name,
          description: newTemplate ? '' : description
        });
      }
    }
  );

  const isValid = useCallback(() => {
    setDisabled(!template || !template.name || template.name.length === 0);
  }, [template]);

  useEffect(() => {
    if (id !== 'blank') {
      getTemplate();
    } else {
      setTemplate({
        name: '',
        description: '',
        owner: type === 'superadmin' ? 'superadmin' : 'club'
      });
    }
    getIsSuperAdmin();
  }, [getTemplate, getIsSuperAdmin, id, type]);

  useEffect(() => {
    isValid();
  }, [isValid]);

  if (!template) {
    return null;
  }

  const handleTextChange = (e) => {
    const thisTemplate = { ...template };
    thisTemplate[e.target.name] = e.target.value;
    setTemplate(thisTemplate);
    isValid();
  };

  // so we can use await on the exportHtml call to allow the loading state
  // of the save button to work correctly we need to convert the unlayer
  // exportHtml callback to a promise
  const exportHtmlPromise = () => {
    return new Promise((resolve, reject) => {
      emailEditorRef.current.editor.exportHtml(async (data) => {
        const { design, html } = data;
        await onTemplateSaveClick({
          ...template,
          templateJSON: design,
          templateHTML: html
        });
        navigate(-1, { replace: true });
        resolve();
      });
    });
  };

  const exportHtml = async () => {
    setLoading(true);
    await exportHtmlPromise();
    setLoading(false);
  };

  const handleTemplateLoad = (ref) => {
    setEmailEditorRef(ref);
  };

  const handleBackClick = () => {
    navigate(-1, { replace: true });
  };

  return (
    <>
      <div style={{ padding: 0 }}>
        <PEButton
          sx={{ marginRight: 1 }}
          startIcon={<BackIcon />}
          onClick={handleBackClick}
          color='primary'
        >
          Back
        </PEButton>
        <PEButton
          disabled={disabled}
          loading={loading}
          onClick={exportHtml}
          startIcon={<SaveIcon />}
        >
          Save
        </PEButton>
      </div>
      <TemplateMeta
        name={template.name}
        nameError={disabled}
        description={template.description}
        owner={template.owner}
        type={type}
        onChange={handleTextChange}
        isSuperAdmin={isSuperAdmin}
      />
      <TemplateEditor
        templateJSON={template.templateJSON}
        onLoad={handleTemplateLoad}
        newTemplate={newTemplate}
      />
    </>
  );
};

Template.propTypes = {
  id: PropTypes.string.isRequired,
  onTemplateSaveClick: PropTypes.func.isRequired,
  newTemplate: PropTypes.bool
};

export default Template;
