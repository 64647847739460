import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

const Spinner = (props) => {
  const { colour, size } = props;

  return (
    <CircularProgress
      variant='indeterminate'
      disableShrink
      size={size || 24}
      sx={{
        color: colour
      }}
    />
  );
};

Spinner.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.number
};

export default Spinner;
