import PEDialog from '../PEDialog.js';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import MemberEventsTable from './MemberEventsTable.js';
import Spinner from '../Spinner.js';

const StyledSpan = styled('span')(({ theme }) => ({
  fontSize: '14px',
  marginLeft: '20px'
}));

const MemberEventsDialog = (props) => {
  const { onClose, open, loading, events, notFoundMessage, boundsContainer } = props;

  if (loading) {
    return (
      <PEDialog
        onClose={onClose}
        open={open}
        boundsContainer={boundsContainer}
      >
        <Spinner
          colour='#999'
          size={24}
        />
      </PEDialog>
    );
  } else if (!events || !events.length) {
    return (
      <PEDialog
        onClose={onClose}
        open={open}
        boundsContainer={boundsContainer}
      >
        {notFoundMessage}
      </PEDialog>
    );
  }

  const firstEvent = events[0];

  return (
    <PEDialog
      onClose={onClose}
      open={open}
      minWidth={650}
      boundsContainer={boundsContainer}
      title={
        <>
          {firstEvent.member.firstName} {firstEvent.member.lastName}
          <StyledSpan>{firstEvent.email}</StyledSpan>
        </>
      }
    >
      <Box sx={{ marginTop: '20px' }}>
        <MemberEventsTable
          events={events}
        />
      </Box>
    </PEDialog>
  );
};

MemberEventsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  events: PropTypes.array,
  loading: PropTypes.bool,
  notFoundMessage: PropTypes.string,
  boundsContainer: PropTypes.instanceOf(Element)
};

export default MemberEventsDialog;
