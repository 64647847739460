import gql from 'graphql-tag';

const devMutations = {
  sign: gql`
    mutation ($toSign: JSONObject!) {
      sign(toSign: $toSign)
    }
  `,
};

export default devMutations;
