import PropTypes from 'prop-types';
import { eventQueries } from '../../../graphql/queries';
import { useLazyQuery } from '@apollo/client';
import { useState, useRef, useCallback } from 'react';
import Box from '@mui/material/Box';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { getMarker } from '../../../helpers/map.js';
import MemberEventsByIdDialog from '../../../Components/MemberEvents/MemberEventsByIdDialog.js';

const clusterMarkers = true;

// try to show show US and CA
const INITIAL_COORDS = { lng: -95, lat: 42 };
const INITIAL_ZOOM = 4;

// how many markers to retrieve with a single request
const PAGE_SIZE = 1000;

const Map = (props) => {
  const { jwt, mapHeight, containerRef } = props;
  const mapRef = useRef();
  const mapClustererRef = useRef();
  const allMarkersRef = useRef([]);

  const [currentFocusedMemberId, setCurrentFocusedMemberId] = useState(null);
  const handleMarkerClick = useCallback((memberId) => {
    setCurrentFocusedMemberId(memberId);
  }, [setCurrentFocusedMemberId]);

  const [getMapEvents] = useLazyQuery(eventQueries.mapEventsByMessageId, {
    fetchPolicy: 'no-cache',
    variables: {
      jwt,
      paginationInput: {
        pageSize: PAGE_SIZE
      }
    },
    onCompleted: (data) => {
      if (!data || !data.mapEventsByMessageId) { return; }


      const { items, paginationKey } = data.mapEventsByMessageId;

      // create the markers for all the events added so far...
      const newMarkers = [];

      items.forEach(item => {
        if (!item.member) { return; }

        const { lat, lon, id } = item.member;

        const position = { lat: lat, lng: lon };
        const marker = getMarker({
          position,
          onClick: () => { handleMarkerClick(id); }
        });
        newMarkers.push(marker);
      });

      if (clusterMarkers) {
        // ... and add them via the clusterer
        // so they don't appear individually
        mapClustererRef.current.addMarkers(newMarkers);
      } else {
        newMarkers.forEach(marker => {
          marker.setMap(mapRef.current);
          allMarkersRef.current.push(marker);
        });
      }

      // if there's any more events to load, load them
      // this will call onCompleted again with the new batch
      if (paginationKey) {
        getMapEvents({
          variables: {
            jwt,
            paginationInput: {
              pageSize: PAGE_SIZE,
              paginationKey
            }
          }
        });
      }
    }
  });

  // initialize map. once we know that's set up, start pulling in events via graphql
  //
  // this is a callback ref, called when the div has definitely rendered. see:
  // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const divRenderedRef = useCallback((div) => {
    // only initialize map if:
    // - the container div is ready
    // - the map itself (mapRef) hasn't been initialized yet
    if (!div || mapRef.current) { return; }

    const map = new window.google.maps.Map(div, {
      center: INITIAL_COORDS,
      zoom: INITIAL_ZOOM
    });
    mapRef.current = map;
    mapClustererRef.current = new MarkerClusterer({
      map,
      renderer: {
        render: ({ count, position }, stats) => {
          return getMarker({
            colourValue: count,
            colourMax: stats.clusters.markers.max,
            labelText: String(count),
            position
          })
        }
      }
    });
    getMapEvents();
  }, [getMapEvents]);

  return (
    <>
      <Box
        ref={divRenderedRef}
        id='map'
        sx={{width: '100%', height: mapHeight}}
      />
      {currentFocusedMemberId && (
        <MemberEventsByIdDialog
          open={true}
          memberId={currentFocusedMemberId}
          jwt={jwt}
          boundsContainer={containerRef.current}
          onClose={() => { setCurrentFocusedMemberId(null); }}
        />
      )}
    </>
  );
};

Map.propTypes = {
  jwt: PropTypes.string.isRequired,
  mapHeight: PropTypes.number.isRequired,
  containerRef: PropTypes.object
};

export default Map;
