import Subheading from './Subheading.js';

const NotOpened = () => {
  return (
    <>
      <Subheading>
        What does this mean?
      </Subheading>
      <p>
        These recipients don’t appear to have opened this email yet. However, due to way we track opens, some of these people might have opened it but we’re not able to tell.
      </p>
    </>
  );
};

export default NotOpened;
