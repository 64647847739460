import Subheading from './Subheading.js';

const Clicks = () => {
  return (
    <>
      <Subheading>
        What does this mean?
      </Subheading>
      <p>
        When you send an email with links, we append those links with unique tracking data. When a recipient clicks one of your links, the tracking information redirects them through our server, which is how we’re able to track links.
      </p>
      <p>
        This report shows you the total number of different recipients that have clicked a link in the email they received, even if they have clicked more than once.
      </p>
    </>
  );
};

export default Clicks;
