import PropTypes from 'prop-types';
const ViewWrapper = ({ children, isMs, bgColour }) => {
  return (
    <div style={{padding: isMs ? 0 : 10, backgroundColor: bgColour || '#fff' }}>
      {children}
    </div>
  );
}

ViewWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isMs: PropTypes.bool,
  bgColour: PropTypes.string
};

export default ViewWrapper;
