import Subheading from './Subheading.js';

const Dropped = () => {
  return (
    <>
      <Subheading>
        What does this mean?
      </Subheading>
      <p>
        When you send an email to a player whose address has bounced in the past, or to an address that previously reported you for sending spam, we drop them from the e-blast. We do this to protect your (and our!) reputation with email servers. (If we continued to send to bad addresses, it looks to recipients’ email servers that you are a potential spammer and this will harm your ability to send to other players’ addresses.)
      </p>
      <Subheading>
        What can I do about it?
      </Subheading>
      <p>
        If we drop an email because of a previous bounce, please carefully check the player’s email address. If it’s definitely correct, we can reinstate their address for future emails. Please contact <a href='mailto:support@pianola.net'>support@pianola.net</a> in this case.
      </p>
      <p>
        If we dropped an email because of a spam report, we are unable to reinstate their address unless they undo their spam report. Please see the ‘spam’ section for more information.
      </p>
    </>
  );
};

export default Dropped;
