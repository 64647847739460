import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle
} from '@mui/material';

const ConfirmAction = ({open, message, onConfirm, onNotConfirm}) => {
  return (
    <Dialog
      open={open}
      onClose={onNotConfirm}
      sx={{'& .MuiDialog-paperScrollPaper': {
        padding: '10px 23px 23px 10px',
        position: 'relative'
      }}}
    >
      <DialogTitle>
        {message ? message : 'Are you sure?'}
      </DialogTitle>
      <DialogActions>
        <Button variant='contained' onClick={onConfirm}>
          Yes
        </Button>
        <Button variant='contained' onClick={onNotConfirm} autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmAction.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onNotConfirm: PropTypes.func.isRequired
};

export default ConfirmAction;
