import { eventQueries } from '../../graphql/queries';
import { useQuery } from '@apollo/client';
import ErrorScreen from '../../Components/ErrorScreen.js';
import { getCode } from '../../helpers/error.js';
import PropTypes from 'prop-types';
import Spinner from '../../Components/Spinner.js';
import Box from '@mui/material/Box';

const ErrorCheck = (props) => {
  const { jwt, resultsEmailSent, children } = props;

  const { loading, error, data } = useQuery(eventQueries.eventCountsByMessageId, {
    variables: { jwt }
  });

  if (error) {
    const code = getCode(error);
    let heading = 'Something went wrong';
    let message = 'Please try again later';

    if (code === 'UNAUTHENTICATED') {
      heading = 'Unauthorized';
      message = 'Please try refreshing the page or logging into Pianola again.';
    }

    return (
      <ErrorScreen
        heading={heading}
        message={message}
      />
    );
  } else if (data && !loading) {
    const response = data.eventCountsByMessageId;

    if (!response || (!response.processed && !response.dropped)) {
      if (resultsEmailSent === false) {
        return (
          <ErrorScreen
            heading='Email delivery report not available'
            message={(
              <>
                <p>You did not have the results email feature enabled when you uploaded this game, so no emails were sent to players.</p>
                <p>To enable this feature for future games, please go to Admin > Settings > Results email.</p>
              </>
            )}
          />
        );
      } else if (resultsEmailSent === null) {
        return (
          <ErrorScreen
            heading='Email delivery report not available'
            message={(
              <>
                <p>Either the delivery statistics are not ready yet, or the results email feature wasn't enabled when the game was uploaded.</p>
                <p>If you were expecting to see delivery statistics, please contact <a href='mailto:support@pianola.net'>support@pianola.net</a> for help.</p>
              </>
            )}
          />
        );
      } else {
        return (
          <ErrorScreen
            heading='Email delivery report not yet available'
            message={(
              <>
                <p>It takes a few minutes for the delivery statistics to show up here. Come back in a short while.</p>
                <p>If you're still not seeing any data, please contact <a href='mailto:support@pianola.net'>support@pianola.net</a> for help.</p>
              </>
            )}
          />
        );
      }
    }
  }

  if (loading) {
    return (
      <Box sx={{ textAlign: 'center', marginTop: '200px' }}>
        <Spinner size={36} colour='#999' />
      </Box>
    );
  }

  return children;
};

ErrorCheck.propTypes = {
  jwt: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  resultsEmailSent: PropTypes.bool
};

export default ErrorCheck;
