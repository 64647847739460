import { useParams, useSearchParams } from 'react-router-dom';
import MemberEventsTable from './MemberEventsTable/MemberEventsTable.js';
import { parseBooleanSearchParam } from '../../helpers/url.js';

const MemberEmailEvents = () => {
  const { jwt } = useParams();
  const [searchParams] = useSearchParams();

  const fullList = parseBooleanSearchParam(searchParams.get('fullList'));

  return (
    <MemberEventsTable
      jwt={jwt}
      fullList={fullList}
    />
  );
};

export default MemberEmailEvents;
