import React from 'react';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import ErrorScreen from './Components/ErrorScreen.js';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorScreen
          heading={'Something went wrong'}
          message={'If this error persists please contact support'}
        />
      )
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};

export default ErrorBoundary;
