import PropTypes from 'prop-types';
import Spinner from '../Spinner.js';
import CenteredRow from './CenteredRow.js';

const TableFeedback = (props) => {
  const { loading, rows, colSpan } = props;

  if (loading) {
    return (
      <CenteredRow
        colSpan={colSpan}
        paddingTop='100px'
      >
        <Spinner
          colour='#999'
          size={32}
        />
      </CenteredRow>
    );
  } else if (!rows || rows.length === 0) {
    return (
      <CenteredRow
        colSpan={colSpan}
        paddingTop='100px'
      >
        No data available.
      </CenteredRow>
    );
  }

  return null;
};

TableFeedback.propTypes = {
  colSpan: PropTypes.number.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool
};

export default TableFeedback;
