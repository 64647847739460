import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import TemplateGridItem from './TemplateGridItem.js';
import TemplateGridSkeleton from './Skeletons/TemplateGridSkeleton.js';
import AddTemplateButton from './AddTemplateButton.js';
import usePENavigate from '../../../Hooks/usePENavigate.js';

const TemplateGrid = ({
  jwt,
  type,
  templates,
  onTemplateClick,
  onDeleteClick,
  selectedTemplate,
  canEdit,
  canCreate,
  canDuplicate,
  small
}) => {
  const navigate = usePENavigate();

  const handleTemplateClick = templateId => () => {
    if (onTemplateClick) {
      onTemplateClick(templateId);
    }
  };

  const handleAddClick = useCallback(() => {
    navigate(`/${jwt}/templates/new/blank`, { state: { type } });
  }, [navigate, jwt, type]);

  const handleEditClick = useCallback(templateId => () => {
    navigate(`/${jwt}/templates/${templateId}`, { state: { type } });
  }, [navigate, jwt, type]);

  const handleDuplicateClick = useCallback(baseTemplateId => () => {
    navigate(`/${jwt}/templates/new/${baseTemplateId}`, { state: { type } });
  }, [navigate, jwt, type]);

  if (!templates) {
    return <TemplateGridSkeleton small={small} />;
  }

  return (
    <>
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
      >
        {
          canCreate
          ?
          <AddTemplateButton
            onClick={handleAddClick}
          />
          :
          null
        }
        {
          templates.map((template, i) => (
            <Grid
              item key={template.id}
              onClick={handleTemplateClick(template.id)}
            >
              <TemplateGridItem
                template={template}
                canEdit={!onTemplateClick && canEdit}
                canDuplicate={canDuplicate}
                onDuplicateClick={handleDuplicateClick(template.id)}
                onEditClick={handleEditClick(template.id)}
                onDeleteClick={onDeleteClick}
                selected={template.id === selectedTemplate}
                small={small}
                type={type}
              />
            </Grid>
          ))
        }

      </Grid>
    </>
  );
};

TemplateGrid.propTypes = {
  jwt: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  templates: PropTypes.array,
  onTemplateClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  selectedTemplate: PropTypes.string,
  canEdit: PropTypes.bool,
  canCreate: PropTypes.bool,
  canDuplicate: PropTypes.bool,
  small: PropTypes.bool
};

export default TemplateGrid;
