import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
import PEButton from './PEButton.js';

const LoadMoreButton = (props) => {
  const { fetchMore, paginationKey } = props;
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const handleLoadMoreClick = useCallback(async () => {
    setIsLoadingMore(true);
    await fetchMore({
      variables: {
        paginationInput: {
          pageSize: 30,
          paginationKey
        }
      }
    });
    setIsLoadingMore(false);
  }, [setIsLoadingMore, fetchMore, paginationKey]);

  // if this is not set, there's no more results to load
  if (!paginationKey) {
    return null;
  }

  return (
    <PEButton
      onClick={handleLoadMoreClick}
      loading={isLoadingMore}
    >
      Load more
    </PEButton>
  );
};

LoadMoreButton.propTypes = {
  fetchMore: PropTypes.func.isRequired,
  paginationKey: PropTypes.object
};

export default LoadMoreButton;
