import React from 'react';
import { useSearchParams } from 'react-router-dom';

const ClubContext = React.createContext();

const ClubContextProvider = (props) => {
  const { children } = props;
  const [searchParams] = useSearchParams();
  const app = searchParams.get('app');
  const isMs = app === 'ms';

  const clubUrlSlug = searchParams.get('clubUrl');

  let organisationName = searchParams.get('organisationName');
  // unaffiliated club
  if (!organisationName || organisationName === 'null') {
    organisationName = null;
  }

  return (
    <ClubContext.Provider
      value={{ isMs, organisationName, clubUrlSlug }}
    >
      {children}
    </ClubContext.Provider>
  );
};

const ClubContextConsumer = ClubContext.Consumer;

export { ClubContextProvider, ClubContextConsumer };
export default ClubContext;
