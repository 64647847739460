import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const StyledIconButton = styled(Button)(({ theme }) => ({
  ...theme.components.iconButton
}));

const PEIconButton = (props) => {
  const { children, ...rest } = props;

  return (
    <StyledIconButton
      disableRipple
      disableElevation
      variant='contained'
      {...rest}
    >
      {children}
    </StyledIconButton>
  );
};

PEIconButton.propTypes = {
  children: PropTypes.node.isRequired
};

export default PEIconButton;
