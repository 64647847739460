import PropTypes from 'prop-types';
import { useContext } from 'react';
import ClubContext from '../../../Components/ClubContext.js';
import PETable from '../../../Components/PETable/PETable.js';
import Spinner from '../../../Components/Spinner.js';
import { eventQueries } from '../../../graphql/queries';
import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { getFormattedDate, getTimezone, getFormattedTime } from '../../../helpers/date.js';
import { getEmailDetailsUrl } from '../../../helpers/url.js';

const timezone = getTimezone();

const Container = styled(Box)(({ theme }) => ({
  '.MuiTableContainer-root': {
    border: 0
  },
  '.MuiTable-root': {
    border: 0
  },
  '.MuiTableHead-root .MuiTableCell-root': {
    background: '#fff',
    fontSize: 12,
    padding: '0 5px 3px 5px',
    color: '#aaa',
    lineHeight: '22px',
    border: 0
  },
  '.MuiTableCell-root': {
    fontSize: 15,
    padding: '3px 5px',
    lineHeight: '22px',
    border: 0,
    verticalAlign: 'top',
    // the first two columns are date and time and need to be short
    // the first one specifically needs to match the one that's above it
    // (outside the iframe, in pianola)
    '&:nth-of-type(1)': {
      width: 117,
      paddingLeft: 0
    },
    '&:nth-of-type(2)': {
      width: 80
    },
    // subject
    '&:nth-of-type(3)': {
      width: 300,
      maxWidth: 300,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    // the final column in here will be the event note
    // which contains a whole lot of machine text, some of which is one single long word
    // these styles prevent the final column from smooshing the other columns together
    '&:last-child': {
      width: 400,
      overflowWrap: 'break-word',
      paddingRight: 0
    }
  }
}));

const MemberEventsTable = (props) => {
  const { jwt, fullList } = props;
  const { clubUrlSlug, isMs } = useContext(ClubContext);

  const { fetchMore, loading, data } = useQuery(eventQueries.eventsByMemberId, {
    variables: {
      jwt,
      paginationInput: {
        pageSize: fullList ? 50 : 10
      }
    }
  });

  if (loading) {
    return (
      <Box sx={{ textAlign: 'center', marginTop: '100px' }}>
        <Spinner size={36} colour='#999' />
      </Box>
    );
  }

  let events = [];
  let paginationKey;

  if (data && data.eventsByMemberId) {
    events = data.eventsByMemberId.items;

    // only show load more button if we're looking at the full list -
    // if this is the partial list, there'll be a separate link for the user to click
    if (fullList) {
      paginationKey = data.eventsByMemberId.paginationKey;
    } else {
      // there's a small chance the server may return more than 10 entries
      // (see https://bitbucket.org/jamesward/pianola-emails/src/6374d16f4f0e35e549c70c291af7c954dbcab5d5/api/helpers/dynamodb.js#lines-102)
      // - cutting the list down to size is only a problem if the pagination key needs to be used
      events = events.slice(0, 10);
    }
  }

  const columns = [
    'Date',
    'Time' + (isMs ? `(${timezone})` : ''),
    'Subject',
    'Event',
    'Note'
  ];
  const rows = events.map(event => {
    const messageUrl = getEmailDetailsUrl(event, clubUrlSlug);

    return {
      id: event.id,
      data: [
        getFormattedDate(event.timestamp),
        getFormattedTime(event.timestamp),
        messageUrl ?
          (<a href={messageUrl} target='_top'>{event.subject}</a>) :
          event.subject,
        event.eventType,
        event.reason
      ]
    };
  });

  return (
    <Container>
      <PETable
        rows={rows}
        columns={columns}
        fetchMore={fetchMore}
        paginationKey={paginationKey}
        maxHeight={fullList ? 557 : 305}
        stickyHeader
      />
    </Container>
  );
};

MemberEventsTable.propTypes = {
  jwt: PropTypes.string.isRequired,
  fullList: PropTypes.bool
};

export default MemberEventsTable;
