const moment = window.moment;

export const getFormattedDate = (timestamp) => {
  const momentDate = moment(timestamp);
  // returns e.g. '15 Jul 2022'
  return momentDate.format('D MMM YYYY');
};

export const getTimezone = () => {
  const timezone = moment.tz(moment.tz.guess()).format('z');
  // return e.g. 'BST'
  return timezone;
};

export const getDetailedFormattedTime = (timestamp) => {
  const momentDate = moment(timestamp);
  // returns e.g. '12:34:56'
  return momentDate.format('HH:mm:ss');
};

export const getFormattedTime = (timestamp) => {
  const momentDate = moment(timestamp);
  // returns e.g. '12:34'
  return momentDate.format('HH:mm');
};
