import gql from 'graphql-tag';
import { templateFragments } from '../fragments';

const templateQueries = {
  template: gql`
    query ($id: ID!) {
      template(id: $id) {
        ...FullTemplate
      }
    }
    ${templateFragments.fullTemplate}
  `,
  templates: gql`
    query (
      $jwt: String!,
      $type: TemplateTypeEnum!
    ) {
      templates(
        jwt: $jwt,
        type: $type
      ) {
        ...PartialTemplate
      }
    }
    ${templateFragments.partialTemplate}
  `,
};

export default templateQueries;
