import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { templateMutations } from '../../graphql/mutations';
import Template from './Components/Template.js';

const EditTemplate = () => {
  const { jwt, id } = useParams();

  const { state } = useLocation();
  const { type } = state;

  const [updateTemplate] = useMutation(templateMutations.updateTemplate);

  const handleTemplateSaveClick = async (template) => {
    // save the design json and the html
    template.jwt = jwt;
    await updateTemplate({ variables: {...template }});
  };

  return (
    <div>
      <Template
        jwt={jwt}
        id={id}
        type={type}
        onTemplateSaveClick={handleTemplateSaveClick}
      />
    </div>
  );
};

export default EditTemplate;
