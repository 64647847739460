const pianolaTheme = {
  globalStyles: {
    a: {
      color: '#46766c',
      textDecoration: 'none',
      '&:hover': {
        color: '#666',
        textDecoration: 'underline'
      }
    }
  },
  typography: {
    fontFamily: '"helvetica neue", helvetica, arial, sans-serif',
    headingFontFamily: '"Asap", "open sans", "helvetica neue", helvetica, arial, sans-serif'
  },
  palette: {
    primary: {
      main: '#619A8F',
      dark: '#71a49a'
    },
    secondary: {
      main: '#FFCC00',
      dark: '#FFD633',
      contrastText: '#fffefe'
    },
    delete: {
      main: '#d00',
      dark: '#E33333',
      contrastText: '#fff'
    },
    text: {
      // primary is in baseTheme, shared between both templates
      secondary: '#666'
    }
  },
  components: {
    button: {
      backgroundColor: '#6dae9b',
      border: '1px solid #539582',
      borderRadius: '6px',
      fontSize: 18,
      textTransform: 'none',
      ':hover, :focus, :active': {
        backgroundColor: '#4a8574',
        borderColor: '#417465'
      }
    },
    dialog: {
      title: {
        color: '#46766c',
        background: '#fff'
      }
    }
  },
  views: {
    emailStats: {
      eventPicker: {
        container: {},
        eventLabel: {
          fontSize: 14
        },
        percentage: {
          fontSize: 30
        },
        eventCount: {
          fontSize: 15
        },
        buttonContainer: {
          borderRadius: 5,
          height: 122,
          '.MuiSvgIcon-root': {
            width: '0.95em',
            height: '0.95em'
          }
        }
      },
      eventsTable: {
        border: '1px solid #e6e6e6',
        borderTop: 0
      },
      memberSearch: {}
    }
  }
};

export default pianolaTheme;
