export const getTemplateManagerUrl = (ownerId, isMs, slug) => {
  // document.referrer will only work when the iframe is to a single page within pianola-emails
  // if pages are internally linked the the referrer will become the page that was linked from
  // and will break the template manager url
  // this solution is good for how things work now as it is simole, cross domain friendly and 
  // cross browser friendly (tested in FF, Chrome, Safari and Edge)
  // but another solution should be considered in the future if iframed pages start linking 
  // between each other!!!
  if (isMs) {
    return `${document.referrer}Club-acbl/Templates/TemplateManager?sendingRegionId=${ownerId}`;
  } else {
    return `${document.referrer}Club-${slug}/Templates/TemplateManager`;
  }
};

export const getEmailDetailsUrl = (event, clubUrlSlug) => {
  let path;

  switch (event.messageType) {
    case 'Message':
      const messageId = event.messageId.replace('m-', '');
      path = `Club-${clubUrlSlug}/Messages/View/${messageId}`;
      break;
    case 'Session':
      path = `Results/Session${event.sessionId}`;
      break;
    case 'Renewal':
      path = `Club-${clubUrlSlug}/Renewals/Status/${event.renewalId}`;

      if (event.reminderNumber) {
        path += `?rem=${event.reminderNumber}`;
      }
      break;
    case 'Invite':
    default:
      return null;
  }

  return `${document.referrer}${path}`;
};

// deal with bool values passed via pianola app
// respecting null / false / undefined
export const parseBooleanSearchParam = (paramValue) => {
  switch (paramValue) {
    case 'null':
      return null;
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return undefined;
  }
};
