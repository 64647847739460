import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Typography
} from '@mui/material';

const Heading = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '1.5rem',
  color: theme.palette.primary.main
}));

const ContentTitle = ({ title }) => {
  return (
    <Heading>{title}</Heading>
  );
};

const ContentContainer = ({ title, children }) => {
  return (
    <Card
      variant='outlined'
      sx={{ marginTop: 3, borderColor: '#ddd' }}
    >
      <CardHeader
        sx={{
          backgroundColor: '#f5f5f5',
          borderBottom: 'solid 1px #ddd',
          padding: '.5rem 0 .5rem .7rem'
        }}
        title={<ContentTitle title={title} />}
      />
      <CardContent sx={{ padding: '2rem' }}>
        {children}
      </CardContent>
    </Card>
  );
};

ContentContainer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default ContentContainer;
