import { InMemoryCache } from '@apollo/client';

// required for pagination
// https://www.apollographql.com/docs/react/pagination/core-api#merging-paginated-results
const mergePaginatedResponses = (existing, incoming) => {
  const existingItems = (existing && existing.items) || [];
  const incomingItems = (incoming && incoming.items) || [];

  return {
    ...incoming,
    items: [
      ...existingItems,
      ...incomingItems
    ]
  };
};

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        eventsByMessageId: {
          keyArgs: ['jwt', 'eventTypeFilter'],
          merge: mergePaginatedResponses
        },
        eventsByMemberId: {
          keyArgs: ['jwt'],
          merge: mergePaginatedResponses
        }
      }
    }
  }
});

export default cache;
