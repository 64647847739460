const baseTheme = {
  globalStyles: {
    p: {
      margin: '20px 0 0 0'
    }
  },
  typography: {
    fontSize: 14
  },
  palette: {
    text: {
      primary: '#333'
    },
    delete: {
      main: '#d00',
      dark: '#E33333',
      contrastText: '#fff'
    }
  }
};

export default baseTheme;
