import { useState, useContext } from 'react';
import { eventQueries } from '../../../../graphql/queries';
import { useQuery } from '@apollo/client';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PropTypes from 'prop-types';
import MemberEventsByIdDialog from '../../../../Components/MemberEvents/MemberEventsByIdDialog.js';
import PETableHeadCell from '../../../../Components/PETable/PETableHeadCell.js';
import PETableCell from '../../../../Components/PETable/PETableCell.js';
import TableFeedback from '../../../../Components/PETable/TableFeedback.js';
import CenteredRow from '../../../../Components/PETable/CenteredRow.js';
import LoadMoreButton from '../../../../Components/LoadMoreButton.js';
import ClubContext from '../../../../Components/ClubContext.js';
import { getFormattedDate, getTimezone, getFormattedTime } from '../../../../helpers/date.js';

const timezone = getTimezone();

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    cursor: 'pointer',
    background: '#f4f5f6',
    '.chevron': {
      opacity: 1
    }
  }
}));

const StyledTableBody = styled(TableBody)(({ theme }) => ({
  // remove the last row's bottom border
  // because that doubles with the border around the iframe
  '.MuiTableRow-root:last-child': {
    '.MuiTableCell-root': {
      border: 0
    }
  }
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: 500,
  ...theme.views.emailStats.eventsTable
}));

const EventsTable = (props) => {
  const {
    jwt,
    currentEventType,
    containerRef
  } = props;

  const { loading, data, fetchMore } = useQuery(eventQueries.eventsByMessageId, {
    variables: {
      jwt,
      eventTypeFilter: currentEventType,
      paginationInput: {
        pageSize: 50
      }
    }
  });
  const [currentFocusedMemberId, setCurrentFocusedMemberId] = useState(null);
  const { organisationName, isMs } = useContext(ClubContext);

  let events = [];
  let paginationKey;

  if (data) {
    const result = data.eventsByMessageId || {};
    events = result.items || [];
    paginationKey = result.paginationKey;
  }

  const handleRowClick = (eventId) => {
    const event = events.find(e => e.id === eventId);

    if (event && event.memberId) {
      setCurrentFocusedMemberId(event.memberId);
    } else {
      setCurrentFocusedMemberId(null);
    }
  };

  let infoColumnName;

  if (!currentEventType) {
    infoColumnName = 'Event/Action';
  } else if (currentEventType === 'click') {
    infoColumnName = 'URL';
  } else if (currentEventType === 'bounce' || currentEventType === 'dropped') {
    infoColumnName = 'Reason';
  }

  return (
    <>
      <StyledTableContainer
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow sx={{ height: 58 }}>
              <PETableHeadCell>Date</PETableHeadCell>
              <PETableHeadCell>
                Time {isMs ? `(${timezone})` : ''}
              </PETableHeadCell>
              {organisationName && (
                <PETableHeadCell>{organisationName} #</PETableHeadCell>
              )}
              <PETableHeadCell>Name</PETableHeadCell>
              <PETableHeadCell>Email</PETableHeadCell>
              {infoColumnName && (
                <PETableHeadCell>{infoColumnName}</PETableHeadCell>
              )}
              <PETableHeadCell></PETableHeadCell>
            </TableRow>
          </TableHead>
          <StyledTableBody>
            <TableFeedback
              loading={loading}
              rows={events}
              colSpan={7}
            />
            {events.map((event, i) => {
              let infoColumnData;

              if (!currentEventType) {
                infoColumnData = event.eventType;
              } else if (currentEventType === 'click') {
                infoColumnData = event.url;
              } else if (currentEventType === 'bounce' || currentEventType === 'dropped') {
                infoColumnData = event.reason;
              }

              return (
                <StyledTableRow
                  key={event.id}
                  onClick={() => handleRowClick(event.id)}
                >
                  <PETableCell>{getFormattedDate(event.timestamp)}</PETableCell>
                  <PETableCell>{getFormattedTime(event.timestamp)}</PETableCell>
                  {organisationName && (
                    <PETableCell>
                      {(event.member && event.member.nationalNumber) || 'N/A'}
                    </PETableCell>
                  )}
                  <PETableCell>
                    {
                      (event.member && event.member.firstName && event.member.lastName)
                        ? `${event.member.firstName} ${event.member.lastName}`
                        : `Unknown (Member ID: ${event.memberId})`
                    }
                  </PETableCell>
                  <PETableCell>{event.email}</PETableCell>
                  {infoColumnName && (
                    <PETableCell>{infoColumnData}</PETableCell>
                  )}
                  <PETableCell
                    align='right'
                    sx={{
                      width: '20px',
                      color: '#999'
                    }}
                  >
                    <ChevronRightIcon
                      size='small'
                      className='chevron'
                      sx={{ opacity: 0 }}
                    />
                  </PETableCell>
                </StyledTableRow>
              );
            })}
            {paginationKey && (
              <CenteredRow colSpan={7}>
                <LoadMoreButton
                  fetchMore={fetchMore}
                  paginationKey={paginationKey}
                />
              </CenteredRow>
            )}
          </StyledTableBody>
        </Table>
      </StyledTableContainer>
      {currentFocusedMemberId && (
        <MemberEventsByIdDialog
          open={true}
          memberId={currentFocusedMemberId}
          jwt={jwt}
          boundsContainer={containerRef.current}
          onClose={() => { setCurrentFocusedMemberId(null); }}
        />
      )}
    </>
  );
};

EventsTable.propTypes = {
  jwt: PropTypes.string.isRequired,
  currentEventType: PropTypes.string,
  containerRef: PropTypes.object
};

export default EventsTable;
