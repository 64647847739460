import gql from 'graphql-tag';

const superAdminMutations = {
  sendMessageToClubs: gql`
    mutation (
      $jwt: String!,
      $templateId: ID!,
      $isTest: Boolean,
      $subject: String!
    ) {
      sendMessageToClubs(
        jwt: $jwt,
        templateId: $templateId,
        isTest: $isTest
        subject: $subject
      )
    }
  `
};

export default superAdminMutations;
