import { useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import HelpIcon from '@mui/icons-material/HelpOutlined';
import PEDialog from '../../../../Components/PEDialog.js';
import _ from 'lodash';
import { formatNumber } from '../../../../helpers/string.js';

const EventLabel = styled('div')(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 'bold',
  fontFamily: theme.typography.headingFontFamily,
  ...theme.views.emailStats.eventPicker.eventLabel,
  '.is-active &': {
    color: 'inherit'
  }
}));

const Percentage = styled('div')(({ theme }) => ({
  fontWeight: 'bold',
  fontFamily: theme.typography.headingFontFamily,
  ...theme.views.emailStats.eventPicker.percentage
}));

const EventCount = styled('div')(({ theme }) => ({
  fontWeight: 'bold',
  fontFamily: theme.typography.headingFontFamily,
  ...theme.views.emailStats.eventPicker.eventCount
}));

const HelpButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  color: theme.palette.text.secondary,
  '.is-active &': {
    color: 'inherit'
  }
}));

const Arrow = styled('span')(({ theme }) => ({
  position: 'absolute',
  width: 0,
  height: 0,
  border: '20px solid transparent',
  borderTop: `20px solid ${theme.palette.primary.main}`,
  bottom: '-40px',
  left: '50%',
  transform: 'translateX(-50%)',
  transition: 'opacity 150ms',
  opacity: 0,
  '.is-active &': {
    opacity: 1
  }
}));

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  border: '#bbb solid',
  borderWidth: '1px',
  marginRight: theme.spacing(1),
  marginBottom: '40px',
  flex: 1,
  padding: theme.spacing(1),
  cursor: 'pointer',
  color: theme.palette.primary.main,
  backgroundColor: '#fff',
  textAlign: 'center',
  position: 'relative',
  transition: 'color 150ms, background-color 150ms',
  '&:hover': {
    outline: '1px solid #bbb',
    '&.is-active': {
      outline: 0
    }
  },
  '&:last-child': {
    marginRight: 0
  },
  '&.is-active': {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    border: 0
  },
  'div, button': {
    transition: 'color 150ms'
  },
  ...theme.views.emailStats.eventPicker.buttonContainer
}));

const EventTypeButton = (props) => {
  const {
    label,
    explainerContent,
    explainerTitle,
    eventCount,
    totalCount,
    onClick,
    isActive
  } = props;
  const [showExplainer, setShowExplainer] = useState(false);

  const pct = 100 * eventCount / totalCount;

  const handleHelpButtonClick = (e) => {
    // prevent the containing button from being selected
    e.stopPropagation();
    setShowExplainer(true);
  };

  const handleCloseClick = (e) => {
    // prevent the containing button from being selected
    // (clicks on the modal backdrop still bubble up to this component)
    e.stopPropagation();
    setShowExplainer(false);
  };

  return (
    <Container onClick={onClick} className={isActive && 'is-active'}>
      <EventLabel>{label}</EventLabel>
      <HelpButton aria-label='about' onClick={handleHelpButtonClick}>
        <HelpIcon fontSize='small' />
      </HelpButton>
      <Percentage>{_.round(pct, 2) + '%'}</Percentage>
      <EventCount>{formatNumber(eventCount)}</EventCount>

      <PEDialog
        onClose={handleCloseClick}
        title={explainerTitle}
        open={showExplainer}
      >
        {explainerContent}
      </PEDialog>
      <Arrow aria-hidden='true'/>
    </Container>
  );
};

EventTypeButton.propTypes = {
  label: PropTypes.string.isRequired,
  explainerTitle: PropTypes.node.isRequired,
  explainerContent: PropTypes.node.isRequired,
  eventCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool
};

export default EventTypeButton;
