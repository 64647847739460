import { useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useRef } from 'react';
import EventsList from './EventsList/EventsList.js';
import EventsMap from './EventsMap/EventsMap.js';
import MemberSearch from './MemberSearch/MemberSearch.js';
import Box from '@mui/material/Box';
import ErrorCheck from './ErrorCheck.js';
import ClubContext from '../../Components/ClubContext.js';
import { parseBooleanSearchParam } from '../../helpers/url.js';

const MAP_HEIGHT = 500;

const EmailStats = (props) => {
  const { jwt } = useParams();
  const { isMs } = useContext(ClubContext);
  const [searchParams] = useSearchParams();
  const mapContainerRef = useRef(null);
  const tableContainerRef = useRef(null);

  const resultsEmailSent = parseBooleanSearchParam(searchParams.get('resultsEmailSent'));

  return (
    <Box sx={{width: '100%'}}>
      <ErrorCheck
        jwt={jwt}
        resultsEmailSent={resultsEmailSent}
      >
        {isMs && (
          <Box
            ref={mapContainerRef}
            sx={{
              height: MAP_HEIGHT,
              marginBottom: '30px'
            }}
          >
            <EventsMap
              jwt={jwt}
              mapHeight={MAP_HEIGHT}
              containerRef={mapContainerRef}
            />
          </Box>
        )}
        <Box sx={{
          marginBottom: '30px',
          marginTop: isMs ? undefined : '10px'
        }}>
          <MemberSearch
            jwt={jwt}
          />
        </Box>
        <Box ref={tableContainerRef}>
          <EventsList
            jwt={jwt}
            containerRef={tableContainerRef}
          />
        </Box>
      </ErrorCheck>
    </Box>
  );
};

export default EmailStats;
