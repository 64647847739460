import gql from 'graphql-tag';
import { eventFragments } from '../fragments';

const eventQueries = {
  eventCountsByMessageId: gql`
    query ($jwt: ID!) {
      eventCountsByMessageId(jwt: $jwt) {
        id
        messageId
        click
        delivered
        uniquedelivered
        bounce
        uniquebounce
        open
        dropped
        uniquedropped
        processed
        uniqueprocessed
        spamreport
        uniqueopen
        uniqueclick
      }
    }
  `,
  eventsByMessageId: gql`
    query (
      $jwt: ID!,
      $eventTypeFilter: EventTypeEnum,
      $paginationInput: PaginationInput
    ) {
      eventsByMessageId(
        jwt: $jwt,
        eventTypeFilter: $eventTypeFilter,
        paginationInput: $paginationInput
      ) {
        items {
          ...FullEvent
        }
        paginationKey
      }
    }
    ${eventFragments.fullEvent}
  `,
  eventsByMessageAndMemberId: gql`
    query (
      $memberId: ID!,
      $jwt: ID!
    ) {
      eventsByMessageAndMemberId(
        memberId: $memberId,
        jwt: $jwt
      ) {
        ...FullEvent
      }
    }
    ${eventFragments.fullEvent}
  `,
  eventsByMessageIdAndMemberSearch: gql`
    query (
      $searchString: String!,
      $jwt: ID!
    ) {
      eventsByMessageIdAndMemberSearch(
        searchString: $searchString,
        jwt: $jwt
      ) {
        ...FullEvent
      }
    }
    ${eventFragments.fullEvent}
  `,
  // only member ID queries get to look for subject because
  // that results in a hit to the Pianola DB
  // and member events pages are the only ones that show them
  eventsByMemberId: gql`
    query (
      $jwt: ID!,
      $paginationInput: PaginationInput
    ) {
      eventsByMemberId(
        jwt: $jwt,
        paginationInput: $paginationInput
      ) {
        items {
          ...FullEvent
          subject
        }
        paginationKey
      }
    }
    ${eventFragments.fullEvent}
  `,
  mapEventsByMessageId: gql`
    query (
      $jwt: ID!,
      $paginationInput: PaginationInput,
    ) {
      mapEventsByMessageId(
        jwt: $jwt,
        eventTypeFilter: processed,
        paginationInput: $paginationInput
      ) {
        items {
          id
          member {
            id
            lat
            lon
          }
        }
        paginationKey
      }
    }
  `
};

export default eventQueries;
