import { Grid, Skeleton } from '@mui/material';

const TemplateSkeleton = () => {
  return (
    <div style={{position: 'relative'}}>
      <Grid container wrap='nowrap' sx={{position: 'absolute'}}>
        <Grid>
          <Skeleton variant='rectangular' width={750} height={800} />
        </Grid>
        <Grid sx={{marginLeft: 2}}>
          <Skeleton variant='rectangular' width={450} height={800} />
        </Grid>
      </Grid>
    </div>
  );
};

export default TemplateSkeleton;
