import { eventQueries } from '../../graphql/queries';
import { useQuery } from '@apollo/client';
import MemberEventsDialog from './MemberEventsDialog.js';
import PropTypes from 'prop-types';

const MemberEventsByIdDialog = (props) => {
  const { memberId, jwt, ...rest } = props;
  const { loading, data } = useQuery(eventQueries.eventsByMessageAndMemberId, {
    variables: { jwt, memberId }
  });

  const events = data && data.eventsByMessageAndMemberId;

  return (
    <MemberEventsDialog
      loading={loading}
      events={events}
      notFoundMessage={'Couldn\'t retrieve activity for this member'}
      {...rest}
    />
  );
};

MemberEventsByIdDialog.propTypes = {
  memberId: PropTypes.string.isRequired,
  jwt: PropTypes.string.isRequired
};

export default MemberEventsByIdDialog;
