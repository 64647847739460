import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const Container = styled('div')(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '20px'
}));

const Heading = styled('h1')(({ theme }) => ({
  marginTop: 0
}));

const ErrorScreen = (props) => {
  const { heading, message } = props;

  return (
    <Container>
      <Heading>{heading}</Heading>
      <div>{message}</div>
    </Container>
  );
};

ErrorScreen.propTypes = {
  heading: PropTypes.node.isRequired,
  message: PropTypes.node.isRequired
};

export default ErrorScreen;
