import { ApolloClient, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { graphqlEndpoint } from '../helpers/env.js';
import { getJwt } from '../helpers/auth.js';
import { getMessage } from '../helpers/error.js';
import cache from './cache.js';
import * as Sentry from '@sentry/browser';

const httpLink = createHttpLink({
  uri: graphqlEndpoint
});

const errorLink = onError((err) => {
  const errMessage = getMessage(err);
  console.log(err);

  Sentry.withScope(scope => {
    // stringified otherwise Sentry shows nested data as [Object object]
    scope.setExtra('errData', JSON.stringify(err));
    Sentry.captureException(new Error(errMessage));
  });
});

const authLink = setContext((_, { headers } ) => {
  const token = getJwt();

  if (!token) {
    return { headers };
  }

  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`
    }
  };
});

const client = new ApolloClient({
  uri: graphqlEndpoint,
  cache,
  link: authLink
    .concat(errorLink)
    .concat(httpLink)
});

export default client;
