const marketingSolutionsTheme = {
  globalStyles: {
    a: {
      color: '#1C2455'
    }
  },
  palette: {
    primary: {
      main: '#1C2455',
      // in a 1984 style in this context dark === light
      // its a lighter colour but it gets the dark name as dark is the default
      // hover theme colour (can't figure out to set another name as hover)
      // same for the secondary colour below
      dark: '#494F77'
    },
    secondary: {
      main: '#F26541',
      dark: '#F48367',
      contrastText: '#fffefe'
    },
    text: {
      // primary is in baseTheme, shared between both templates
      secondary: '#333'
    }
  },
  typography: {
    fontFamily: '"Open Sans", "helvetica neue", helvetica, arial, sans-serif',
    headingFontFamily: '"Open Sans", "helvetica neue", helvetica, arial, sans-serif'
  },
  components: {
    button: {
      borderRadius: 4,
      fontSize: 20,
      padding: '2px 12px',
      textTransform: 'none'
    },
    iconButton: {
      minWidth: 40,
      borderRadius: 4,
      padding: '10px 2px 10px 2px'
    },
    dialog: {
      title: {
        color: '#1C2455',
        background: '#f5f5f5'
      }
    }
  },
  views: {
    emailStats: {
      eventPicker: {
        container: {
          paddingLeft: 8,
          paddingRight: 8
        },
        eventLabel: {},
        percentage: {
          fontSize: 40
        },
        eventCount: {
          fontSize: 20
        },
        buttonContainer: {
          borderRadius: 10,
          height: 150
        }
      },
      eventsTable: {},
      memberSearch: {
        right: 10
      }
    }
  }
};

export default marketingSolutionsTheme;
