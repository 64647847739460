import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';

const PETableHeadCell = styled(TableCell)(({ theme }) => ({
  color: '#33393d',
  background: '#f4f5f6',
  border: '1px solid #e6e6e6',
  borderLeft: 0,
  borderRight: 0,
  padding: '0.5em 0.75em',
  fontWeight: 'bold',
  lineHeight: 1
}));

export default PETableHeadCell;
