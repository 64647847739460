import Subheading from './Subheading.js';

const Delivered = () => {
  return (
    <>
      <Subheading>
        What does this mean?
      </Subheading>
      <p>
        Delivered means that the recipient’s email server accepted your email for delivery.
      </p>
      <p>
        Sometimes, however, a recipient’s email server may have sent your email to their spam/junk folder. If someone is reported here as “delivered” but they don’t see your email in their in-box, please ask them to check their spam folder.
      </p>
    </>
  );
};

export default Delivered;
