import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { templateMutations } from '../../graphql/mutations';
import { Typography } from '@mui/material';
import TemplateGrid from './Components/TemplateGrid.js';
import ContentContainer from '../../Components/ContentContainer.js';
import ViewWrapper from '../../Components/ViewWrapper.js';
import useTemplatesQuery from '../../Hooks/useTemplatesQuery.js';

const SuperAdmin = () => {
  const { jwt } = useParams();

  const {
    templates: allTemplates,
    onDeleteTemplate
  } = useTemplatesQuery(jwt, ['default', 'simple', 'superadmin']);

  const defaultTemplates = allTemplates && allTemplates.filter(
    template => template.type === 'default'
  );

  const simpleTemplates = allTemplates && allTemplates.filter(
    template => template.type === 'simple'
  );

  const superAdminTemplates = allTemplates && allTemplates.filter(
    template => template.type === 'superadmin'
  );

  const [deleteTemplate] = useMutation(templateMutations.deleteTemplate);

  const deleteTemplateHandler = useCallback((templateId, onDeleteTemplate) => {
    const variables = { id: templateId, jwt };
    deleteTemplate({ variables });
    onDeleteTemplate(templateId);
  }, [deleteTemplate, jwt]);

  const handleDeleteClick = useCallback(templateId => {
    deleteTemplateHandler(templateId, onDeleteTemplate)
  }, [deleteTemplateHandler, onDeleteTemplate]);

  return (
    <ViewWrapper bgColour='#f8f5f0'>
      <ContentContainer title='Default templates'>
        <TemplateGrid
          jwt={jwt}
          type='default'
          canEdit
          canCreate
          canDuplicate
          templates={defaultTemplates}
          onDeleteClick={handleDeleteClick}
        />
      </ContentContainer>
      <ContentContainer title='Simple templates'>
        <Typography variant='subtitle2'>
          Remember to add:
        </Typography>
        <Typography variant='subtitle2' sx={{fontWeight: 'bold', marginBottom: 2}}>
          {' {content}, {unsubscribe}, {region-name} and {region-address}'}
        </Typography>
        <TemplateGrid
          jwt={jwt}
          type='simple'
          canEdit
          canCreate
          canDuplicate
          templates={simpleTemplates}
          onDeleteClick={handleDeleteClick}
        />
      </ContentContainer>
      <ContentContainer title='Pianola templates'>
        <TemplateGrid
          jwt={jwt}
          type='superadmin'
          canEdit
          canCreate
          canDuplicate
          templates={superAdminTemplates}
          onDeleteClick={handleDeleteClick}
        />
      </ContentContainer>
    </ViewWrapper>
  );
};

export default SuperAdmin;
