import { useState } from 'react';
import PropTypes from 'prop-types';
import LinesEllipsis from 'react-lines-ellipsis'
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Box,
  Popover,
  Tooltip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CopyIcon from '@mui/icons-material/ContentCopy';
import MailIcon from '@mui/icons-material/MailOutline';
import ConfirmAction from '../../../Components/ConfirmAction.js';
import PEIconButton from '../../../Components/PEIconButton.js';
import { SendModal } from './SendModal.js';

const TemplateGridItem = (props) => {
  const {
    template,
    canEdit,
    canDuplicate,
    selected,
    small,
    type,
    onEditClick,
    onDeleteClick,
    onDuplicateClick
  } = props;

  // const cloudFlareUrl = 'https://pianola.net/cdn-cgi/image/width=240,quality=100,trim=0;600;600;600/';

  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const popoverOpen = Boolean(anchorEl);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [sendOpen, setSendOpen] = useState(false);

  const handleDeleteClick = () => {
    setConfirmOpen(true);
  };
  const handleClose = () => {
    setConfirmOpen(false);
  };
  const handleDeleteConfirmed = () => {
    setConfirmOpen(false);
    onDeleteClick(template.id);
  };
  const handleSendClick = () => {
    setSendOpen(true);
  };

  const handleSendModalClose = () => {
    console.log('handleSendModalClose');
    setSendOpen(false);
  };

  return (
    <>
      <Card
        variant='outlined'
        sx={{
          width: small ? '10rem' : '15rem',
          height: small ? '11.8rem' : '17.5rem',
          margin: '.5rem',
          border: 'solid 1px #ddd',
          ...((!canDuplicate) && {
            '&:hover': {
              borderColor: selected ? 'primary.main' : 'secondary.main',
              'WebkitTransform': 'scale(1.05)',
              'msTransform': 'scale(1.05)',
              'MozTransform': 'scale(1.05)',
              transform: 'scale(1.05)',
              'WebkitBoxShadow': '0 2px 10px rgba(0, 0, 0, 0.1)',
              'MozBoxShadow': '0 2px 10px rgba(0, 0, 0, 0.1)',
              'boxShadow': '0 2px 10px rgba(0, 0, 0, 0.1)',
              cursor: 'pointer'
            }
          })
        }}
      >
        <CardMedia
          sx={{objectPosition: 'top'}}
          component='img'
          height={small ? 90 : 140}
          image={`${template.thumbnail}?timestamp=${new Date().getTime()}`}
          alt={template.name}
        />
        <CardContent sx={{p: 1}}>
          <Typography
            gutterBottom
            variant={small ? 'caption' : 'h6'}
            component='div'
          >
            <LinesEllipsis
              text={template.name}
              maxLine='1'
              ellipsis='...'
              trimRight
              basedOn='letters'
            />
          </Typography>
          {
            !small
            ?
            <>
              <Typography
                variant='body2'
                color='text.secondary'
                aria-owns={popoverOpen ? 'mouse-over-popover' : undefined}
                aria-haspopup='true'
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                component='div'
              >
                <LinesEllipsis
                  text={template.description ? template.description : ' '}
                  maxLine='1'
                  ellipsis='...'
                  trimRight
                  basedOn='letters'
                />
              </Typography>
              <Popover
                id='mouse-over-popover'
                sx={{
                  pointerEvents: 'none',
                }}
                open={popoverOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                {template.description}
              </Popover>
            </>
            :
            null
          }
        </CardContent>
        {
          canEdit || canDuplicate
          ?
          <CardActions>
            {
              canEdit
              ?
              <PEIconButton
                onClick={onEditClick}
                color='primary'
              >
                <Tooltip title='Edit' placement='top' arrow>
                  <EditIcon fontSize='small' />
                </Tooltip>
              </PEIconButton>
              :
              null
            }
            {
              canDuplicate
              ?
              <PEIconButton
                onClick={onDuplicateClick}
                color='secondary'
              >
                <Tooltip title='Duplicate' placement='top' arrow>
                  <CopyIcon fontSize='small' />
                </Tooltip>
              </PEIconButton>
              :
              null
            }
            {
              canEdit
              ?

              <PEIconButton
                onClick={handleDeleteClick}
                color='delete'
              >
                <Tooltip title='Delete' placement='top' arrow>
                  <DeleteIcon fontSize='small' />
                </Tooltip>
              </PEIconButton>

              :
              null
            }
            {
              type === 'superadmin'
              ? (
                <PEIconButton
                  onClick={handleSendClick}
                  sx={{ backgroundColor: 'green'}}
                >
                  <Tooltip title='Send' placement='top' arrow>
                    <MailIcon fontSize='small' />
                  </Tooltip>
                </PEIconButton>
              )
              : null
            }
          </CardActions>
          :
          <Box
            sx={{
              bgcolor: selected ? 'primary.main' : 'secondary.main',
              color: '#fff',
              textAlign: 'center',
              alignItems: 'center',
              fontWeight: 700,
              fontSize: small ? '.7rem' : '.9rem',
              mt: selected ? '.6rem' : '.6rem',
              pt: selected ? '.9rem' : '1rem',
              pb: selected ? '.7rem' : '1rem'
            }}
          >
            {
              selected
              ?
              <CheckIcon sx={{fontSize: 23}}/>
              :
              <>CHOOSE TEMPLATE</>
            }
          </Box>
        }
      </Card>
      <ConfirmAction
        open={confirmOpen}
        onConfirm={handleDeleteConfirmed}
        onNotConfirm={handleClose}
      />
      <SendModal
        open={sendOpen}
        template={template}
        onSendModalClose={handleSendModalClose}
      />
    </>
  );
};

TemplateGridItem.propTypes = {
  template: PropTypes.object.isRequired,
  canEdit: PropTypes.bool,
  canDuplicate: PropTypes.bool,
  selected: PropTypes.bool,
  small: PropTypes.bool,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onDuplicateClick: PropTypes.func
};

export default TemplateGridItem;
