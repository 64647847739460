import { useContext } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { marketingSolutionsTheme, pianolaTheme } from '../mui/themes.js';
import ClubContext from './ClubContext.js';

const PEThemeProvider = (props) => {
  const { children } = props;
  const { isMs } = useContext(ClubContext);

  const theme = isMs ? marketingSolutionsTheme : pianolaTheme;

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

export default PEThemeProvider;
