import Subheading from './Subheading.js';

const UniqueOpens = () => {
  return (
    <>
      <Subheading>
        What does this mean?
      </Subheading>

      <p>
        This report shows you the total number of different recipients who opened your email, even if they opened it more than once.
      </p>
      <p>
        Each time you send an email, we include a tiny invisible graphic at the bottom of your message. This “web beacon” is unique for each recipient and each email you send. When someone opens your email, that beacon is downloaded from our server, which is how we record it as an “open”.
      </p>

      <Subheading>
        Limitations
      </Subheading>

      <p>
        This method of tracking is the industry standard and will give you a general idea about the performance of your email. However, there are limitations. As it relies on loading an image (albeit invisible) from our server, this won’t work if a recipient has set their email program to not download and display images.
      </p>
      <p>
        So, although everyone you see on this report definitely did open your email don’t assume that someone who’s not on this list didn’t - they may have opened it without images showing.
      </p>
    </>
  );
};

export default UniqueOpens;
