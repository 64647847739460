import { useState, useCallback } from 'react';
import EventTypePicker from './EventTypePicker/EventTypePicker.js';
import EventsTable from './EventsTable/EventsTable.js';
import PropTypes from 'prop-types';

const EventsList = (props) => {
  const { jwt, containerRef } = props;
  const [currentEventType, setCurrentEventType] = useState('delivered');

  const handleEventTypePick = useCallback((newEventType) => {
    setCurrentEventType(newEventType);
  }, [setCurrentEventType]);

  return (
    <div>
      <EventTypePicker
        jwt={jwt}
        currentEventType={currentEventType}
        onEventTypePick={handleEventTypePick}
      />
      <EventsTable
        jwt={jwt}
        currentEventType={currentEventType}
        containerRef={containerRef}
      />
    </div>
  );
};

EventsList.propTypes = {
  jwt: PropTypes.string.isRequired,
  containerRef: PropTypes.object
};

export default EventsList;
