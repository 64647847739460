import { useEffect, useContext } from 'react';
import ClubContext from './ClubContext.js';

// adds the required font for normal Pianola / marketing solutions
// the client should've already cached these by the time they're here
const FontLoader = () => {
  const { isMs } = useContext(ClubContext);

  //<link rel="stylesheet" type="text/css" href="" media="all" />
  useEffect(() => {
    if (typeof isMs === 'undefined') { return; }

    const linkElement = document.createElement('link');
    linkElement.rel = 'stylesheet';
    linkElement.type = 'text/css';
    linkElement.href = isMs ?
      '//fonts.googleapis.com/css?family=Lato:300,400,700,900' :
      '//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800|Inconsolata:400,700|Asap:400,400i,700,700i';
    linkElement.media = 'all';

    document.head.appendChild(linkElement);
  }, [isMs]);

  return null;
};

export default FontLoader;
