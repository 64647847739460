import gql from 'graphql-tag';

const eventFragments = {
  fullEvent: gql`
    fragment FullEvent on Event {
      id
      messageId
      eventType
      email
      memberId
      member {
        id
        firstName
        lastName
        nationalNumber
      }
      timestamp
      reason
      url
      messageType
      sessionId
      renewalId
      reminderNumber
    }
  `
};

export default eventFragments;
