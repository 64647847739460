import client from './graphql/client.js';
import { ApolloProvider } from '@apollo/client';
import { Routes, Route } from 'react-router-dom';
import EmailStats from './Views/EmailStats/EmailStats.js';
import MemberEmailEvents from './Views/MemberEmailEvents/MemberEmailEvents.js';
import Dev from './Views/Dev/Dev.js';
import ErrorBoundary from './ErrorBoundary.js';
import GlobalStyles from '@mui/material/GlobalStyles';
import CssBaseline from '@mui/material/CssBaseline';
import TemplateManager from './Views/Templates/TemplateManager.js';
import SuperAdmin from './Views/Templates/SuperAdmin.js';
import Compose from './Views/Templates/Compose.js';
import NewTemplate from './Views/Templates/NewTemplate.js';
import EditTemplate from './Views/Templates/EditTemplate.js';
import SelectTemplate from './Views/Templates/SelectTemplate.js';
import { ClubContextProvider } from './Components/ClubContext.js';
import FontLoader from './Components/FontLoader.js';
import PEThemeProvider from './Components/PEThemeProvider.js';

function App() {
  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <ClubContextProvider>
          <FontLoader/>
          <PEThemeProvider>
            <CssBaseline/>
            <GlobalStyles styles={(theme) => theme.globalStyles}/>
            <Routes>
              <Route
                path='stats/:jwt'
                element={<EmailStats/>}
              />
              <Route
                path='member/:jwt'
                element={<MemberEmailEvents/>}
              />
              <Route
                path='/:jwt/compose/:templateId'
                element={<Compose/>}
              />
              <Route
                path='/:jwt/templates'
                element={<TemplateManager/>}
              />
              <Route
                path='/:jwt/superadmin'
                element={<SuperAdmin/>}
              />
              <Route
                exact
                path='/:jwt/templates/new/:baseTemplateId'
                element={<NewTemplate/>}
              />
              <Route
                exact
                path='/:jwt/templates/select'
                element={<SelectTemplate/>}
              />
              <Route
                exact
                path='/:jwt/templates/select/:templateId'
                element={<SelectTemplate/>}
              />
              <Route
                path='/:jwt/templates/:id'
                element={<EditTemplate/>}
              />
              <Route
                path='dev'
                element={<Dev/>}
              />
            </Routes>
          </PEThemeProvider>
        </ClubContextProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
}

export default App;
