import { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import { Button, Stack, Switch, Typography } from '@mui/material';
import { env } from '../../helpers/env.js';
import { useMutation } from '@apollo/client';
import { devMutations } from '../../graphql/mutations';
import { persistJwt } from '../../helpers/auth.js';

const Dev = () => {
  if (env !== 'local') {
    throw new Error('Only allowed to do dev login on local');
  }


  const [sign] = useMutation(devMutations.sign);
  const [iframeUrl, setIframeUrl] = useState('');
  const [app, setApp] = useState('ms');
  // this is the hardcoded height that Pianola shows this iframe at
  const [iframeHeight, setIframeHeight] = useState(1000);

  const authenticate = async (name) => {
    // authenticate via cookie
    const authSignResponse = await sign({
      variables: {
        toSign: {
          id: 123,
          name: name || 'Johnny John'
        }
      }
    });

    const authJwt = authSignResponse.data.sign;
    persistJwt(authJwt);
  };

  const getLinkJwt = async (toSign) => {
    const linkSignResponse = await sign({
      variables: {
        toSign
      }
    });
    return linkSignResponse.data.sign;
  };

  const handleMemberEmailEventsClick = async () => {
    await authenticate();

    // the link is a signed JWT - this is authorization
    const linkJwt = await getLinkJwt({
      memberId: '337',
      clubId: '186'
    });

    setIframeUrl(`/member/${linkJwt}?app=${app}&organisationName=EBU&clubUrl=leeds&fullList=true`);
    setIframeHeight(557);
  };

  const handleEmailStatsClick = async () => {
    await authenticate();

    // the link is a signed JWT - this is authorization
    const linkJwt = await getLinkJwt({
      messageId: 'm-88848',
      clubId: '186'
    });

    setIframeUrl(`/stats/${linkJwt}?app=${app}&organisationName=ACBL`);
    setIframeHeight(1295);
  };

  const handleTemplateManagerClick = async () => {
    await authenticate();

    const ownerJwt = await getLinkJwt({
      ownerId: '123',
      ownerType: app === 'ms' ? 'region' : 'club'
    });

    setIframeUrl(`/${ownerJwt}/templates?ownerName=Unit%20100&app=${app}`);
    setIframeHeight(1295);
  };

  const handleSuperAdminClick = async () => {
    await authenticate('Pianola');

    const ownerJwt = await getLinkJwt({
      ownerType: 'superadmin'
    });

    setIframeUrl(`/${ownerJwt}/superadmin?app=${app}`);
    setIframeHeight(1295);
  };

  const handleComposeClick = async () => {
    await authenticate();

    const ownerAndMessageJwt = await getLinkJwt({
      ownerId: '123',
      ownerType: app === 'ms' ? 'region' : 'club',
      messageId: 4321
    });

    setIframeUrl(`/${ownerAndMessageJwt}/compose/district-simple-1?app=${app}`);
    setIframeHeight(1295);
  };

  const handleSelectClick = async () => {
    await authenticate();

    const ownerAndMessageJwt = await getLinkJwt({
      ownerId: '123',
      ownerType: app === 'ms' ? 'region' : 'club',
      messageId: 4321
    });

    setIframeUrl(`/${ownerAndMessageJwt}/templates/select/simple-1?app=${app}&ownerId=123`);
    setIframeHeight(1295);
  };

  const handleSwitchChange = (event) => {
    const checked = event.target.checked ? 'ms' : 'pianola';
    setApp(checked);
    if (iframeUrl !== '') {
      if (checked === 'ms') {
        setIframeUrl(iframeUrl.replace('pianola', 'ms'));
      } else {
        setIframeUrl(iframeUrl.replace('ms', 'pianola'));
      }
    }
  }

  return (
    <>
      <Card>
        <Stack direction='row' spacing={1} alignItems='center' style={{position: 'relative', left: 16, top: 10}}>
          <Typography>Pianola</Typography>
          <Switch onChange={handleSwitchChange} defaultChecked />
          <Typography>MS</Typography>
        </Stack>
        <CardActions>
          <Button onClick={handleEmailStatsClick}>Email Stats</Button>
          <Button onClick={handleMemberEmailEventsClick}>Member Events</Button>
          <Button onClick={handleTemplateManagerClick}>Template Manager</Button>
          <Button onClick={handleSuperAdminClick}>Super Admin</Button>
          <Button onClick={handleComposeClick}>Compose</Button>
          <Button onClick={handleSelectClick}>Template Select</Button>
        </CardActions>
      </Card>
      {
        iframeUrl ?
        (
          <div style={{
            margin: 10,
            border: '1px solid #ddd',
            width: 1160
          }}>
            <iframe
              title='dev iframe'
              src={iframeUrl}
              style={{
                width: 1158,
                height: iframeHeight,
                border: 0
              }}
            />
          </div>
        )
        :
        null
      }
    </>
  );
};

export default Dev;
