import { useCallback, useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { templateMutations } from '../../graphql/mutations';
import TemplateGrid from './Components/TemplateGrid.js';
import ContentContainer from '../../Components/ContentContainer.js';
import ViewWrapper from '../../Components/ViewWrapper.js';
import TextContainer from './Components/TextContainer.js';
import useTemplatesQuery from '../../Hooks/useTemplatesQuery.js';
import ClubContext from '../../Components/ClubContext';

const TemplateManager = () => {
  const { jwt } = useParams();
  const [searchParams] = useSearchParams();
  const ownerName = searchParams.get('ownerName');

  const { templates, onDeleteTemplate } = useTemplatesQuery(jwt, ['user', 'default']);

  const { isMs } = useContext(ClubContext);
  const officialTemplateCreator = isMs ? 'ACBL' : 'Pianola';
  const templateOrLayout = isMs ? 'templates' : 'layouts';

  const richTemplates = templates && templates.filter(
    template => template.type === 'user'
  );

  const defaultTemplates = templates && templates.filter(
    template => template.type === 'default'
  );

  const [deleteTemplate] = useMutation(templateMutations.deleteTemplate);

  const handleDeleteClick = useCallback(templateId => {
    const variables = { id: templateId, jwt };
    deleteTemplate({ variables });
    onDeleteTemplate(templateId);
  }, [deleteTemplate, onDeleteTemplate, jwt]);

  if (!templates) {
    return null;
  }

  return (
    <ViewWrapper isMs={isMs} bgColour={!isMs && '#f8f5f0'}>
      <ContentContainer title={`${ownerName}'s custom templates`}>
        <TextContainer>
          These are {ownerName}'s own custom templates. You can create new templates from scratch, or use an existing one as the starting point by duplicating it and then editing the copy you made.
        </TextContainer>
        <TemplateGrid
          jwt={jwt}
          type='user'
          canEdit
          canCreate
          canDuplicate
          templates={richTemplates}
          onDeleteClick={handleDeleteClick}
        />
      </ContentContainer>

      <ContentContainer title={`${officialTemplateCreator} designed ${templateOrLayout}`}>
        <TextContainer>
          {
            isMs ?
              <>These are official templates designed by ACBL. You cannot change them, but you can make a duplicate to use as the starting point for a custom template.</>
            :
              <>Here are a variety of pre-built layouts that you can use as a starting point for your own templates. Duplicate any that you like and then customise with your own header, logo, etc and save as your own templates.</>
          }
        </TextContainer>
        <TemplateGrid
          jwt={jwt}
          type='user'
          canDuplicate
          templates={defaultTemplates}
        />
      </ContentContainer>
    </ViewWrapper>
  );
};

export default TemplateManager;
