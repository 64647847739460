import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ClubContext from '../Components/ClubContext.js';

const usePENavigate = () => {
  const { isMs } = useContext(ClubContext);
  const app = isMs ? 'ms' : 'pianola';
  const navigate = useNavigate();

  return (path, state) => {
    navigate(`${path}?app=${app}`, state);
  }
};

export default usePENavigate;
