import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';

const PETableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: '1px solid #e6e6e6',
  padding: '0.75em',
  lineHeight: 1
}));

export default PETableCell;
