import { useParams, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { templateMutations } from '../../graphql/mutations';
import Template from './Components/Template.js';

const NewTemplate = () => {
  const { jwt, baseTemplateId } = useParams();

  const { state } = useLocation();
  const { type } = state;

  const [createTemplate] = useMutation(templateMutations.createTemplate);

  const handleTemplateSaveClick = async (template) => {
    // create new template object to send to mutation
    delete template.id;
    template.jwt = jwt;
    template.type = type;
    await createTemplate({ variables: { ...template } });
  };

  return (
    <div>
      <Template
        id={baseTemplateId}
        onTemplateSaveClick={handleTemplateSaveClick}
        newTemplate
        type={type}
      />
    </div>
  );
};

export default NewTemplate;
