import gql from 'graphql-tag';

const authQueries = {
  isSuperAdmin: gql`
    query {
      isSuperAdmin
    }
  `
};

export default authQueries;
