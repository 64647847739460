import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import EmailEditor from './EmailEditor/EmailEditor.js';
import TemplateSkeleton from './Skeletons/TemplateSkeleton.js';
import { getEnvVar } from '../../../helpers/env.js';
import ClubContext from '../../../Components/ClubContext';

const TemplateEditor = ({ templateJSON, onLoad, onUpdate, newTemplate }) => {
  const emailEditorRef = useRef(null);
  const [templateReady, setTemplateReady] = useState(false);
  const projectId = getEnvVar('UNLAYER_PROJECT_ID');
  const { isMs } = useContext(ClubContext);
 
  const init = (templateJSON) => {
    if (templateJSON) {
      emailEditorRef.current.editor.loadDesign(templateJSON);
    }

    let mergeTags = {
      first_name: {
        name: 'First Name',
        value: '{first-name}',
        sample: 'John'
      }
    };

    if (isMs) {
      // add ACBL Specific merge tags
      mergeTags = { ...mergeTags, }
      mergeTags.acbl_masterpoints_total = {
        name: 'ACBL Masterpoints Total',
        value: '{acbl-masterpoints-total}',
        sample: '100'
      };
      mergeTags.acbl_rank = {
        name: 'ACBL Rank',
        value: '{acbl-rank}',
        sample: 'Life Master'
      };
    }
    emailEditorRef.current.editor.setMergeTags(mergeTags);

    const bodyValues = {
      contentWidth: '650px'
    };

    if (newTemplate) {
      bodyValues.backgroundColor = '#fafafa';
      bodyValues.fontFamily = {
        label: 'Open Sans',
        value: "'Open Sans', Helvetica, Arial, sans-serif"
      };
    }

    emailEditorRef.current.editor.setBodyValues(bodyValues);

    if (onLoad) {
      onLoad(emailEditorRef);
    }
  };

  const handleLoad = () => {
    // editor instance is created
    // load the template here
    // if it fails (when using back button) just ignore the failure
    // and let the handleReady func do the loading
    try {
      init(templateJSON);
    } catch {}
  };

  const handleReady = () => {
    setTemplateReady(true);
    // editor is ready
    // if the handleLoad template load failed (when using back button)
    // load it here instead (bit slower)
    init(templateJSON);
  };

  // the update mechanism is used for autosaving the message compose
  // it's not used for template management
  const handleUpdated = (data) => {
    if (
      onUpdate &&
      (data.type === 'content:modified' || data.type === 'content:added')
    ) {
      emailEditorRef.current.editor.exportHtml(async (template) => {
        onUpdate(template);
      });

      emailEditorRef.current.editor.exportPlainText(async (template) => {
        onUpdate(template);
      });
    }
  };

  return (
    <>
      {
        !templateReady && <TemplateSkeleton />
      }
      <div style={{ border: templateReady ? '1px solid #ddd' : null }}>
        <EmailEditor
          ref={emailEditorRef}
          onLoad={handleLoad}
          onReady={handleReady}
          onUpdated={handleUpdated}
          style={{ height: 800 }}
          options={{ projectId }}
        />
      </div>
    </>
  );
};

TemplateEditor.propTypes = {
  templateJSON: PropTypes.object,
  onLoad: PropTypes.func,
  onUpdate: PropTypes.func,
  newTemplate: PropTypes.bool
};

export default TemplateEditor;
