import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useMutation } from '@apollo/client';
import { superAdminMutations } from '../../../graphql/mutations';
import SendIcon from '@mui/icons-material/Send';
import {
  Box,
  TextField,
  FormControl
} from '@mui/material';
import PEButton from '../../../Components/PEButton.js';
import PEDialog from '../../../Components/PEDialog.js';
const { getMessage } = require('../../../helpers/error.js');

const StyledFormControl = styled(FormControl)({
  padding: '10px 10px 10px 0'
});

export function SendModal ({ open, template, onSendModalClose }) {
  const [subject, setSubject] = useState('');
  const [sendButtonLoading, setSendLoading] = useState(false);
  const [testButtonLoading, setTestButtonLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const { jwt } = useParams();
  
  const [sendMessageToClubs] = useMutation(superAdminMutations.sendMessageToClubs);

  const sendEmail = async (isTest) => {
    setMessage(null);
    await sendMessageToClubs({
      variables: {
        jwt,
        isTest,
        subject,
        templateId: template.id,
      },
      onCompleted: () => {
        setMessage({
          text: 'Done!',
          type: 'info'
        });
      },
      onError: (error) => {
        setMessage({
          text: getMessage(error),
          type: 'error'
        });
      }
    });
  };

  const handleSubjectChange = (e) => {
    console.log('e.target.value', e.target.value);
    setSubject(e.target.value);
  };

  const handleSendButtonClick = async () => {
    setSendLoading(true);
    await sendEmail(false);
    setSendLoading(false);
  };

  const handleTestButtonClick = async () => {
    setTestButtonLoading(true);
    await sendEmail(true);
    setTestButtonLoading(false);
  };

  useEffect(() => {
    setSubject('');
    setMessage(null);
  }, [setSubject, setMessage, open]);

  return (
    <PEDialog
      title='Send email to club admins'
      open={open}
      onClose={onSendModalClose}
    >
      <Box sx={{paddingTop: 2, maxWidth: 600}}>
        Send the email to all club admins or send a test to staging.pianola@gmail.com
      </Box>
      <StyledFormControl margin='normal'>
        <TextField
          name='subject'
          label='Subject'
          value={subject}
          onChange={handleSubjectChange}
          inputProps={{ maxLength: 100 }}
          size='small'
          sx={{width: 560, paddingLeft: 0}}
        />
      </StyledFormControl>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ fontWeight: 'bold', marginTop: 2, color: message && message.type === 'error' ? 'red' : 'green' }}>
          {message && message.text}
        </Box>
        <Box>
          <PEButton
            sx={{marginRight: 2}}
            onClick={handleSendButtonClick}
            loading={sendButtonLoading}
            startIcon={<SendIcon />}
            disabled={subject === ''}
          >
            Send to club admins
          </PEButton>
          <PEButton
            onClick={handleTestButtonClick}
            loading={testButtonLoading}
            startIcon={<SendIcon />}
            disabled={subject === ''}
          >
            Send test
          </PEButton>
        </Box>
      </div>
    </PEDialog>
  );
};