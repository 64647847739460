import gql from 'graphql-tag';
import { templateFragments } from '../fragments';

const templateMutations = {
  updateTemplate: gql`
    mutation (
      $id: ID!,
      $name: String!,
      $description: String,
      $owner: String,
      $jwt: String!,
      $templateJSON: JSON!,
      $templateHTML: String!
    ) {
      updateTemplate(
        id: $id,
        name: $name,
        description: $description,
        owner: $owner,
        jwt: $jwt,
        templateJSON: $templateJSON,
        templateHTML: $templateHTML
      ) {
        ...FullTemplate
      }
    }
    ${templateFragments.fullTemplate}
  `,
  createTemplate: gql`
    mutation (
      $name: String!,
      $description: String,
      $owner: String,
      $type: TemplateTypeEnum,
      $jwt: String!
      $templateJSON: JSON!,
      $templateHTML: String!
    ) {
      createTemplate(
        name: $name,
        description: $description,
        owner: $owner,
        type: $type,
        jwt: $jwt,
        templateJSON: $templateJSON,
        templateHTML: $templateHTML
      ) {
        ...FullTemplate
      }
    }
    ${templateFragments.fullTemplate}
  `,
  deleteTemplate: gql`
    mutation (
      $id: ID!,
      $jwt: String!
    ) {
      deleteTemplate(
        id: $id,
        jwt: $jwt
      ) {
        id
      }
    }
  `
};

export default templateMutations;
