import _ from 'lodash';

export const getGQLError = err => {
  return _.get(err, 'graphQLErrors[0]', null);
}

export const getMessage = (err) => {
  let gqlErr = _.get(err, 'graphQLErrors[0]', null);
  let message;
  if (gqlErr) {
    message = gqlErr.message;
  } else {
    gqlErr = _.get(err, 'errors[0]', null);
    if (gqlErr) {
      message = gqlErr.message;
    } else {
      const netErr = _.get(err, 'networkError', null);
      if (netErr) {
        // its a network err
        message = netErr.message;
      }
    }
  }

  return message || 'Something went wrong. Please try refreshing the page or contact support.';
}

export const getCode = err => {
  let code = _.get(err, 'graphQLErrors[0].extensions.code', null);
  if (!code) {
    code = _.get(err, 'errors[0].extension.code', null);
  }
  if (!code) {
    code = _.get(err, 'networkError.result.errors[0].extensions.code');
  }
  return code;
}
