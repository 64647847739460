import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import MemberSearchField from './MemberSearchField.js';
import MemberEventsBySearchDialog from '../../../Components/MemberEvents/MemberEventsBySearchDialog.js';
import Box from '@mui/material/Box';

const MemberSearch = (props) => {
  const { jwt } = props;
  const [searchString, setSearchString] = useState('');

  const handleMemberSearch = useCallback((searchString) => {
    setSearchString(searchString);
  }, [setSearchString]);

  const handleModalClose = useCallback(() => {
    setSearchString('');
  }, [setSearchString]);

  return (
    <Box sx={{ height: '45px' }}>
      <Box sx={(theme) => ({
          height: '45px',
          position: 'absolute',
          right: 0,
          ...theme.views.emailStats.memberSearch
        })}>
        <MemberSearchField
          onMemberSearch={handleMemberSearch}
        />
      </Box>
      {searchString && (
        <MemberEventsBySearchDialog
          open={true}
          jwt={jwt}
          searchString={searchString}
          onClose={handleModalClose}
        />
      )}
    </Box>
  );
};

MemberSearch.propTypes = {
  jwt: PropTypes.string.isRequired
};

export default MemberSearch;
