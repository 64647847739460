// formats e.g.:
// 1234 -> '1,234'
export const formatNumber = (number) => {
  if (!number) return '0';

  let stringified = number.toString()
  const parts = [];

  while (stringified.length) {
    const part = stringified.slice(-3);
    stringified = stringified.slice(0, Math.max(0, stringified.length - 3));
    parts.unshift(part);
  }

  return parts.join(',');
};
