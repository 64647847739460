import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl
} from '@mui/material';

const StyledFormControl = styled(FormControl)({
  padding: '10px 10px 10px 0'
});

const TemplateMeta = ({
  name,
  nameError,
  description,
  owner,
  type,
  onChange,
  isSuperAdmin
}) => {
  return (
    <>
      <StyledFormControl margin='normal'>
        <TextField
          error={nameError}
          name='name'
          label='Name'
          value={name}
          onChange={onChange}
          inputProps={{ maxLength: 50 }}
          size='small'
          sx={{width: 360, paddingLeft: 0}}
        />
      </StyledFormControl>
      <StyledFormControl margin='normal'>
        <TextField
          name='description'
          label='Description'
          value={description}
          onChange={onChange}
          inputProps={{ maxLength: 100 }}
          size='small'
          sx={{width: 750}}
        />
      </StyledFormControl>
      {
        isSuperAdmin && type !== 'superadmin'
        ?
        <StyledFormControl margin='normal' sx={{width: 200}}>
          <InputLabel id='owner-label'>Owner</InputLabel>
          <Select
            name='owner'
            value={owner}
            label='Owner'
            labelId='owner-label'
            onChange={onChange}
            sx={{width: 200}}
          >
            <MenuItem value='club'>Club</MenuItem>
            <MenuItem value='acbl'>ACBL</MenuItem>
            <MenuItem value='region'>Region</MenuItem>
            <MenuItem value='district'>District</MenuItem>
            <MenuItem value='unit'>Unit</MenuItem>
          </Select>
        </StyledFormControl>
        :
        null
      }
    </>
  );
};

TemplateMeta.propTypes = {
  name: PropTypes.string.isRequired,
  nameError: PropTypes.bool,
  description: PropTypes.string,
  owner: PropTypes.string,
  onChange: PropTypes.func,
  isSuperAdmin: PropTypes.bool
};

export default TemplateMeta;
