import PropTypes from 'prop-types';

const TextContainer = ({ children }) => {
  return (
    <div style={{width: '90%', marginLeft: 10}}>
      <div style={{ marginBottom: '1.5rem', marginTop: '.5rem'}}>
        {children}
      </div>
    </div>
  )
}

TextContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export default TextContainer;
