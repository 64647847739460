import { useContext } from 'react';
import { eventQueries } from '../../graphql/queries';
import { useQuery } from '@apollo/client';
import MemberEventsDialog from './MemberEventsDialog.js';
import PropTypes from 'prop-types';
import ClubContext from '../ClubContext.js';

const MemberEventsBySearchDialog = (props) => {
  const { jwt, searchString, ...rest } = props;
  const { organisationName } = useContext(ClubContext);
  const { loading, data } = useQuery(eventQueries.eventsByMessageIdAndMemberSearch, {
    variables: { jwt, searchString }
  });

  const events = data && data.eventsByMessageIdAndMemberSearch;

  // unaffiliated clubs can only search by email address
  const searchCriteria = organisationName ?
    `${organisationName} number or email address` :
    'email address';

  return (
    <MemberEventsDialog
      loading={loading}
      events={events}
      notFoundMessage={`No activity found for a member with that ${searchCriteria}.`}
      {...rest}
    />
  );
};

MemberEventsBySearchDialog.propTypes = {
  searchString: PropTypes.string.isRequired,
  jwt: PropTypes.string.isRequired
};

export default MemberEventsBySearchDialog;
