import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import PETableCell from './PETableCell.js';
import PETableHeadCell from './PETableHeadCell.js';
import TableFeedback from './TableFeedback.js';
import CenteredRow from './CenteredRow.js';
import LoadMoreButton from '../LoadMoreButton.js';

const StyledTableContainer = styled(TableContainer)(({ theme, maxHeight, noBorders }) => ({
  border: '1px solid #e6e6e6',
  borderTop: 0,
  borderBottom: 0
}));

const PETable = (props) => {
  const {
    columns,
    rows,
    loading,
    paginationKey,
    fetchMore,
    stickyHeader,
    maxHeight
  } = props;

  return (
    <StyledTableContainer
      sx={{maxHeight}}
    >
      <Table
        stickyHeader={stickyHeader}
      >
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <PETableHeadCell key={column}>{column}</PETableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableFeedback
            loading={loading}
            rows={rows}
            colSpan={columns.length}
          />
          {rows.map((row, i) => (
            <TableRow key={row.id}>
              {row.data.map((cell, i) => (
                <PETableCell key={i}>{cell}</PETableCell>
              ))}
            </TableRow>
          ))}
          {paginationKey && fetchMore && (
            <CenteredRow colSpan={columns.length}>
              <LoadMoreButton
                fetchMore={fetchMore}
                paginationKey={paginationKey}
              />
            </CenteredRow>
          )}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

PETable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  paginationKey: PropTypes.object,
  fetchMore: PropTypes.func,
  stickyHeader: PropTypes.bool,
  maxHeight: PropTypes.number
};

export default PETable;
