import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';

const StyledButton = styled(LoadingButton)(({ theme }) => ({
  ...theme.components.button
}));

const PEButton = (props) => {
  const { children, color, ...rest } = props;

  return (
    <StyledButton
      disableRipple
      disableElevation
      variant='contained'
      color={color ? color : 'secondary'}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

PEButton.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string
};

export default PEButton;
