import { useState, useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import TemplateGrid from './Components/TemplateGrid.js';
import TextContainer from './Components/TextContainer.js';
import useTemplatesQuery from '../../Hooks/useTemplatesQuery.js';
import { getTemplateManagerUrl } from '../../helpers/url.js';
import ClubContext from '../../Components/ClubContext';

const Heading = styled(Typography)(() => ({
  marginLeft: 10,
  marginTop: 20,
  fontWeight: 'bold'
}));


const SelectTemplate = () => {
  const { jwt, templateId } = useParams();
  const [searchParams] = useSearchParams();
  const ownerId = searchParams.get('ownerId');
  const slug = searchParams.get('slug');

  const { isMs } = useContext(ClubContext);

  const templateManagerUrl = getTemplateManagerUrl(ownerId, isMs, slug);

  const [selectedTemplate, setSelectedTemplate] = useState(templateId);

  const handleTemplateClick = (clickedTemplateId) => {
    setSelectedTemplate(clickedTemplateId);
    // post the selected template id to the parent of the iframe (pianola)
    const message = `templateId:${clickedTemplateId}`;
    window.top.postMessage(message, '*');
  };

  const { templates } = useTemplatesQuery(jwt, ['user', 'default', 'simple']);;

  let richTemplates = [];
  if (templates) {
    richTemplates = templates && templates.filter(
      template => template.type === 'user'
    );
    // for marketing solutions add the default templates as well as these can be used as they are
    // whereas Pianola default templates are used as 'layouts' that need to be duplicated before they 
    // can be used here
    if (isMs) {
      const defaultTemplates = templates.filter(
        template => template.type === 'default'
      );
      richTemplates = [ ...richTemplates, ...defaultTemplates ];
    }
  }

  const simpleTemplates = templates && templates.filter(
    template => template.type === 'simple'
  );

  return (
    <>
      <div>
        <Heading variant='h5'>
          Rich templates
        </Heading>
        <TextContainer>
          Rich templates are fully customizable and can include images, buttons, multiple columns, and more. 
          {isMs && 'Emails sent using a rich template cost three credits per recipient and include delivery stats for free.'}
          <p>You can create new templates, or edit existing ones, in the <a href={templateManagerUrl} target='_top'>template manager</a>.</p>
        </TextContainer>
        <TemplateGrid
          jwt={jwt}
          type='user'
          templates={richTemplates}
          onTemplateClick={handleTemplateClick}
          selectedTemplate={selectedTemplate}
        />
      </div>
      <div>
        <Heading variant='h5'>
          Simple templates
        </Heading>
        <TextContainer>
          {
            isMs 
            ? 'Simple templates are pre-designed by ACBL, and use a standard font and layout. Emails sent using simple templates cost one credit per recipient. Simple templates cannot be edited, nor can they include images, buttons, or multiple columns.'
            : 'Simple templates are pre-designed by Pianola, and use a standard font and layout. You can apply limited formatting to your emails but can’t change the layout nor include images, buttons etc. For full control over the design and layout of your email, please use a rich template.'
          }
        </TextContainer>
        <TemplateGrid
          jwt={jwt}
          type='simple'
          templates={simpleTemplates}
          onTemplateClick={handleTemplateClick}
          selectedTemplate={selectedTemplate}
        />
      </div>
    </>
  );
};

export default SelectTemplate;
